import { TSCMState } from 'src/redux/scm/scm.slice';
import { RootState } from 'src/redux/store';

export const selectSCMDataSheetResponse = (state: RootState): TSCMState['dataSheetResponse'] =>
  state.scm.dataSheetResponse;

export const selectActiveSCMStep = (state: RootState): TSCMState['activeSCMStep'] =>
  state.scm.activeSCMStep;

export const selectSCMFlow = (state: RootState): TSCMState['isSCMFlow'] => state.scm.isSCMFlow;

export const selectIsAppLoading = (state: RootState): TSCMState['isAppLoading'] =>
  state.scm.isAppLoading;
export const selectSCMCommunityMember = (state: RootState): TSCMState['scmCommunityManager'] =>
  state.scm.scmCommunityManager;

export const selectCommunityMembers = (state: RootState): TSCMState['scmHomeDetails'] =>
  state.scm.scmHomeDetails;

export const selectActiveCommunityListTab = (
  state: RootState,
): TSCMState['activeCommunityListTab'] => state.scm.activeCommunityListTab;

export const selectNewOperationalCommunityUuid = (
  state: RootState,
): TSCMState['newOperationalCommunityUuid'] => state.scm.newOperationalCommunityUuid;

export const selectIsOperationalCommunity = (
  state: RootState,
): TSCMState['isOperationalCommunity'] => state.scm.isOperationalCommunity;

export const selectActiveLiveAccumulatedSubscriptionId = (
  state: RootState,
): TSCMState['activeLiveAccumulatedSubscriptionId'] =>
  state.scm.activeLiveAccumulatedSubscriptionId;

export const selectPreventPushResultsScreen = (
  state: RootState,
): TSCMState['preventPushResultsScreen'] => state.scm.preventPushResultsScreen;

export const selectUnreadMessageCount = (state: RootState): TSCMState['unreadMessageCount'] =>
  state.scm.unreadMessageCount;
