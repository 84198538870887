import React, { useMemo } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/hooks/useAuth';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useMapNavigation } from 'src/hooks/useMapNavigation';
import { useSimulationSubscription } from 'src/hooks/useSimulationSubscription';
import { setSelectedMemberUuid, setSidebarExpanded } from 'src/redux/application/application.slice';
import { selectUnreadMessageCount } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';

import s from './MapSidebarSingleCommunity.module.scss';
import { EActiveTabState } from './MapSidebarSingleCommunity.types';
import { TMapSidebarSingleCommunityTabsProps } from './MapSidebarSingleCommunity.types';

export const MapSidebarSingleCommunityTabs: React.FC<TMapSidebarSingleCommunityTabsProps> = ({
  activeTab,
  handleTabChange,
  handleResultsTabClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const unreadMessageCount = useSelector(selectUnreadMessageCount);

  const { showSimulationResults } = useSimulationSubscription();
  const { zoomIntoConfiguration } = useMapNavigation();
  const { isOwnerCM, isOwnerAdmin } = useIsAdminInspecting();
  const { isMember } = useAuth();
  const { communityMembers } = useConfiguration();
  const { userName } = useAuth();

  const showMessagingPortal = isMember || isOwnerCM || isOwnerAdmin;
  const showUnreadCount = useMemo(() => {
    if (unreadMessageCount === 0) return false;
    return true;
  }, [unreadMessageCount]);

  const handleMessagePortalTabClick = () => {
    handleTabChange(EActiveTabState.MessagingPortal);
    dispatch(setSidebarExpanded(true));
  };

  return (
    <>
      <div className={s.header}>
        <ul className={s.headerNav}>
          {isMember ? (
            <li
              className={classnames({
                [s.active]: activeTab === EActiveTabState.Profile,
              })}>
              <button
                type="button"
                onClick={() => {
                  dispatch(setSidebarExpanded(false));
                  handleTabChange(EActiveTabState.Profile);
                  zoomIntoConfiguration();
                  const communityMember = communityMembers.find(
                    (member) => member.email === userName,
                  );
                  if (!!communityMember?.uuid)
                    dispatch(setSelectedMemberUuid(communityMember.uuid));
                }}
                title="Profile">
                {t('MY_PROFILE')}
              </button>
            </li>
          ) : (
            <li
              className={classnames({
                [s.active]: [EActiveTabState.Settings].includes(activeTab),
              })}>
              <button
                type="button"
                onClick={() => handleTabChange(EActiveTabState.Settings)}
                title="Settings">
                {t('navigation.SETTINGS')}
              </button>
            </li>
          )}
          {!isMember && (
            <li
              className={classnames({
                [s.active]: activeTab === EActiveTabState.Registry,
              })}>
              <button
                type="button"
                onClick={() => handleTabChange(EActiveTabState.Registry)}
                title="Members">
                {t('navigation.MEMBERS')}
              </button>
            </li>
          )}
          {showSimulationResults && (
            <li
              className={classnames(s.communityName, {
                [s.active]:
                  activeTab === EActiveTabState.Community ||
                  activeTab === EActiveTabState.GridMarketResults,
              })}
              title="Results">
              <button type="button" onClick={() => handleResultsTabClick()}>
                {t('RESULTS')}
              </button>
            </li>
          )}
          {showMessagingPortal && (
            <li
              className={classnames(s.communityName, s.withNotoficationBubble, {
                [s.active]: activeTab === EActiveTabState.MessagingPortal,
              })}
              title="MessagingPortal">
              <button type="button" onClick={() => handleMessagePortalTabClick()}>
                {t('MESSAGING_PORTAL')}
                {isMember && showUnreadCount && (
                  <div className={s.notificationsBubble}>
                    <span className={s.notificationsBubbleText}>{unreadMessageCount}</span>
                  </div>
                )}
              </button>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
