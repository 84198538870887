import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { NetworkStatus } from '@apollo/client';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Route, Switch, matchPath } from 'react-router';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import {
  EActiveTabState,
  MapSidebarSingleCommunity,
} from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { ListCommunitiesWrapper } from 'src/features/Communities/ListCommunities/ListCommunitiesWrapper';
import { NewCommunity } from 'src/features/Communities/NewCommity';
import { useReadConfigurationQuery } from 'src/graphql';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useAuth } from 'src/hooks/useAuth';
import { useConfiguration } from 'src/hooks/useConfiguration';
import {
  selectActiveSidebarMainTab,
  selectIsEmbed,
  selectScreenMode,
} from 'src/redux/application/application.selectors';
import { selectTutorialStep } from 'src/redux/application/application.selectors';
import { selectUsername } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationUuid,
  selectCommunityUser,
} from 'src/redux/configuration/configuration.selectors';
import { selectActiveModals } from 'src/redux/modals/modals.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { EScreenMode } from 'src/typings/configuration.types';

import { ConfigurationManagementCentral } from '../ConfigurationManagementCentral';
import s from './MapSidebar.module.scss';
import { TMapSidebarProps } from './MapSidebar.types';

export const MapSidebar: React.FC<TMapSidebarProps> = () => {
  const dispatch = useAppDispatch();
  const location = useAppLocation();

  const isEmbed = useSelector(selectIsEmbed);
  const sidebarModalOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR),
  );
  const tutorialStep = useSelector(selectTutorialStep);
  const activeSidebarMainTab = useSelector(selectActiveSidebarMainTab);
  const communityUser = useSelector(selectCommunityUser);
  const activeModals = useSelector(selectActiveModals);
  const userName = useSelector(selectUsername);
  const screenMode = useSelector(selectScreenMode);
  const configUuid = useSelector(selectActiveConfigurationUuid);

  const [containerWidth, setContainerWidth] = useState<EModalSize | 'perc'>(EModalSize.L370);

  const { isMember } = useAuth();
  const { skipReadConfiguration } = useConfiguration();
  const { networkStatus } = useReadConfigurationQuery({
    skip: skipReadConfiguration,
    variables: { uuid: configUuid! },
  });

  const modalTopMargin = useMemo(
    () =>
      isMember && screenMode === EScreenMode.Mobile
        ? '120px'
        : isMember || !(communityUser == '' || userName === communityUser)
        ? '75px'
        : '120px',
    [isMember, communityUser, userName, screenMode],
  );

  useEffect(() => {
    if (
      !activeModals.includes(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY) &&
      !sidebarModalOpened
    ) {
      dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
    }
  }, [sidebarModalOpened, dispatch, activeModals]);

  const getContainerSize = useCallback(() => {
    const routeMatch = !!matchPath(location.pathname, {
      path: [routesConfig.scmMap(), routesConfig.scmMapCreate()],
      exact: true,
    });
    if (
      !routeMatch &&
      (activeSidebarMainTab == EActiveTabState.Community ||
        activeSidebarMainTab === EActiveTabState.GridMarketResults ||
        activeSidebarMainTab == EActiveTabState.MessagingPortal)
    ) {
      return 'perc';
    } else {
      return EModalSize.L425;
    }
  }, [activeSidebarMainTab, location.pathname]);

  useEffect(() => {
    const containerSize = getContainerSize();
    setContainerWidth(containerSize);
  }, [activeSidebarMainTab, getContainerSize]);

  useEffect(() => {
    const isCreateCommunityRoute = !!matchPath(location.pathname, {
      path: routesConfig.scmMapCreate(),
    });
    if (isCreateCommunityRoute) {
      dispatch(openModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
    }
  }, [dispatch, location.pathname]);

  return (
    <>
      <Switch>
        <Route path={`(${routesConfig.scmMap()})`}>
          <BaseGenericModal
            className={classnames(s.container, {
              [s.containerEmbed]: isEmbed,
              [s.sidebarWithoutNav]: !!matchPath(location.pathname, {
                path: [routesConfig.scmMapCreate(), routesConfig.scmMapResults()],
              }),
            })}
            closedClassName={s.closed}
            size={containerWidth}
            style={{
              zIndex: tutorialStep === 1 ? 99999 : 100,
              pointerEvents: tutorialStep === 1 ? 'none' : 'auto',
              top: modalTopMargin,
            }}
            modalId={EPredefinedModalIds.MODAL_MAP_SIDEBAR}>
            <Switch>
              <Route
                // Use simulations list also for /create route
                path={`${routesConfig.scmMap()}`}
                exact>
                <ListCommunitiesWrapper />
              </Route>
              <ConfigurationManagementCentral>
                {({ handleAssetValuesSavePromise }) => {
                  return (
                    <>
                      <Route path={routesConfig.scmMapResults('', '')}>
                        {networkStatus === NetworkStatus.ready && (
                          <MapSidebarSingleCommunity
                            onAssetValuesSavePromise={handleAssetValuesSavePromise}
                          />
                        )}
                      </Route>
                      <Route path={routesConfig.scmMapCreate()}>
                        <NewCommunity />
                      </Route>
                    </>
                  );
                }}
              </ConfigurationManagementCentral>
            </Switch>
          </BaseGenericModal>
        </Route>
      </Switch>
    </>
  );
};
