import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { EActiveTabState } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';
import { EPredefinedModalIds } from 'src/constants/modals';
import { ApplicationContext } from 'src/contexts/ApplicationContext';
import {
  ConfigType,
  ReadConfigurationDocument,
  useLaunchSimulationMutation,
  usePauseSimulationMutation,
  useReadSimulationResultsStatusLazyQuery,
  useResumeSimulationMutation,
  useStopSimulationMutation,
} from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useSimulationSubscription } from 'src/hooks/useSimulationSubscription';
import { setActiveSidebarMainTab } from 'src/redux/application/application.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectConfigType,
  selectHasUserAllowedResultsLoss,
  selectReadOnly,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  resetSimulationResults,
  setActiveConfigurationJobUuid,
  setSimulationProgress,
  setSimulationStatus,
} from 'src/redux/configuration/configuration.slice';
import { openModal } from 'src/redux/modals/modals.slice';
import { deleteSingleNotification } from 'src/redux/notifications/notificaitons.slice';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { setActiveSCMStep } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { TSimResultsStatus } from 'src/typings/base-types';

export type TRunButtonOptions = {
  title: string;
  icon: TIconNames;
  disabled?: boolean;
  onClick?:
    | TuseSimulationButtons['simulationLaunch']
    | TuseSimulationButtons['simulationPause']
    | TuseSimulationButtons['simulationResume'];
};

export type TStopButtonOptions = {
  icon: TIconNames;
  disabled?: boolean;
  onClick?: TuseSimulationButtons['simulationStop'];
};

export type TuseSimulationButtons = {
  runButtonState: TRunButtonState;
  runButtonOptions: TRunButtonOptions;
  stopButtonState: TStopButtonState;
  stopButtonOptions: TStopButtonOptions;
  simulationLaunch: (uuid?: string | undefined) => Promise<string | null>;
  simulationPause: (uuid?: string | undefined) => Promise<string | null>;
  simulationResume: (uuid?: string | undefined) => Promise<string | null>;
  simulationStop: (uuid?: string | undefined) => Promise<string | null>;
};
export type TRunButtonState =
  | 'run'
  | 'rerun'
  | 'resume'
  | 'pause'
  | 'processing'
  | 'loading'
  | 'stopping'
  | 'disabled';
export type TStopButtonState = 'stop' | 'disabled';
/**
 * TODO: Describe logic of this hook (especially overridingStates)
 */
export function useSimulationButtons(): TuseSimulationButtons {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { triggerResultsLossAlert } = useContext(ApplicationContext);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const simulationStatus = useSelector(selectSimulationStatus);
  const readOnly = useSelector(selectReadOnly);
  const configType = useSelector(selectConfigType);
  const hasUserAllowedResultsLoss = useSelector(selectHasUserAllowedResultsLoss);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);

  const [runButtonState, setRunButtonState] = useState<TRunButtonState>('loading');
  const [stopButtonState, stopRunButtonState] = useState<TStopButtonState>('disabled');

  const [launchSimulation] = useLaunchSimulationMutation({
    refetchQueries: [
      { query: ReadConfigurationDocument, variables: { uuid: activeConfigurationUuid } },
    ],
  });
  const [pauseSimulation] = usePauseSimulationMutation({
    refetchQueries: [
      { query: ReadConfigurationDocument, variables: { uuid: activeConfigurationUuid } },
    ],
  });
  const [resumeSimulation] = useResumeSimulationMutation();
  const [stopSimulation] = useStopSimulationMutation();

  const { resultsStatus } = useConfiguration();
  useSimulationSubscription();

  const isCN = configType === ConfigType.CanaryNetwork;

  const [readSimulationResultsStatus] = useReadSimulationResultsStatusLazyQuery({
    onCompleted(data) {
      if (!!data && !!data?.simulationResults) {
        const results = data.simulationResults;
        if (!!results.status) dispatch(setSimulationStatus(results.status as TSimResultsStatus));
        if (!!results.progressInfo) dispatch(setSimulationProgress(results.progressInfo));
      }
    },
  });

  useEffect(() => {
    const status = !!simulationStatus ? simulationStatus : resultsStatus;

    switch (status) {
      case 'paused':
        setRunButtonState('resume');
        stopRunButtonState('stop');
        break;
      case 'timed-out':
        setRunButtonState('run');
        stopRunButtonState('disabled');
        break;
      case 'stopped':
      case 'error':
      case 'failed':
      case 'finished':
        setRunButtonState('rerun');
        stopRunButtonState('disabled');
        break;
      case 'started':
      case 'running':
        setRunButtonState('disabled');
        stopRunButtonState('stop');
        break;
      case 'queued':
      case 'initializing':
        setRunButtonState('processing');
        stopRunButtonState('disabled');
        break;
      case 'loading':
        setRunButtonState('loading');
        stopRunButtonState('disabled');
        break;
      case 'stopping':
        setRunButtonState('stopping');
        stopRunButtonState('disabled');
        break;
      default:
        // New Configuration does not have jobUuid so we can allow to run
        if (activeConfigurationUuid || !isLoggedIn) {
          setRunButtonState('run');
          stopRunButtonState('disabled');
        } else {
          setRunButtonState('loading');
          stopRunButtonState('disabled');
        }
    }
  }, [
    activeConfigurationUuid,
    activeConfigurationJobUuid,
    isLoggedIn,
    resultsStatus,
    simulationStatus,
  ]);

  const simulationLaunch = useCallback(async () => {
    if (runButtonState === 'rerun' && !hasUserAllowedResultsLoss) {
      await triggerResultsLossAlert();
    }

    dispatch(deleteSingleNotification());
    dispatch(setActiveSCMStep(2));
    dispatch(setActiveSidebarMainTab(EActiveTabState.Settings));

    if (!!activeConfigurationUuid) {
      // Reset results only if hook is used to currently opened simulation
      if (!isCN) {
        dispatch(resetSimulationResults());
      }
      dispatch(setSimulationStatus('initializing'));

      const { data } = await launchSimulation({
        variables: {
          uuid: activeConfigurationUuid!,
        },
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          dispatch(setSimulationStatus('timed-out'));

          const msg = JSON.parse(err.message).concurrency_error;
          dispatch(
            openToast({
              message: msg || 'Something went wrong',
              type: 'error',
            }),
          );
          return msg;
        });

      if (data?.launchSimulation?.job) {
        dispatch(setActiveConfigurationJobUuid(data.launchSimulation.job));
        readSimulationResultsStatus({
          variables: {
            jobId: data.launchSimulation.job,
            uuid: activeConfigurationUuid,
          },
        });
        return data?.launchSimulation?.job;
      }
    } else if (!isLoggedIn) {
      dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
    }
    return null;
  }, [
    isCN,
    runButtonState,
    activeConfigurationUuid,
    isLoggedIn,
    readSimulationResultsStatus,
    triggerResultsLossAlert,
    launchSimulation,
    dispatch,
    hasUserAllowedResultsLoss,
  ]);

  const simulationPause = useCallback(
    async (uuid?: string) => {
      const resultUuid = uuid || activeConfigurationJobUuid;
      if (resultUuid) {
        dispatch(setSimulationStatus('stopping'));
        const { data } = await pauseSimulation({
          variables: {
            jobId: resultUuid!,
          },
        });
        if (data?.pauseSimulation) {
          return data.pauseSimulation;
        }
      }
      return null;
    },
    [activeConfigurationJobUuid, pauseSimulation, dispatch],
  );

  const simulationResume = useCallback(
    async (uuid?: string) => {
      if (uuid || activeConfigurationJobUuid) {
        dispatch(setSimulationStatus('initializing'));
        const { data } = await resumeSimulation({
          variables: {
            jobId: (uuid || activeConfigurationJobUuid)!,
          },
        });
        if (data?.resumeSimulation) {
          return data.resumeSimulation;
        }
      }
      return null;
    },
    [activeConfigurationJobUuid, resumeSimulation, dispatch],
  );

  const simulationStop = useCallback(
    async (uuid?: string) => {
      const resultUuid = uuid || activeConfigurationJobUuid;
      if (resultUuid) {
        dispatch(setSimulationStatus('stopping'));

        const { data } = await stopSimulation({
          variables: {
            jobId: resultUuid!,
          },
        });
        if (data?.stopSimulation) {
          return data.stopSimulation;
        }
      }
      return null;
    },
    [dispatch, activeConfigurationJobUuid, stopSimulation],
  );

  const runButtonOptions = useMemo<TRunButtonOptions>(() => {
    switch (runButtonState) {
      case 'run':
        return {
          onClick: simulationLaunch,
          title: isOperationalCommunity ? 'Launch Operation' : t('commands.RUN_SIMULATION'),
          icon: isOperationalCommunity ? 'arrow-right-full' : 'play',
          disabled: readOnly,
        };
      case 'rerun':
        return {
          onClick: simulationLaunch,
          title: isOperationalCommunity ? t('commands.RERUN') : t('commands.RERUN_SIMULATION'),
          icon: 'refresh',
          disabled: readOnly,
        };
      case 'resume':
        return {
          onClick: simulationResume,
          title: isOperationalCommunity ? 'Resume' : 'Resume Simulation',
          icon: 'play',
          disabled: readOnly,
        };
      case 'pause':
        return {
          onClick: simulationPause,
          title: isOperationalCommunity ? 'Pause' : 'Pause Simulation',
          icon: 'pause',
          disabled: readOnly,
        };
      case 'processing':
        return {
          title: t('feedback.INITIALIZING'),
          icon: 'spinner',
          disabled: true,
        };
      case 'loading':
        return {
          title: t('feedback.LOADING'),
          icon: 'spinner',
          disabled: true,
        };
      case 'stopping':
        return {
          title: t('feedback.STOPPING'),
          icon: 'spinner',
          disabled: true,
        };
      case 'disabled':
        return {
          title: isOperationalCommunity ? t('feedback.RUNNING') : t('feedback.SIMULATION_RUNNING'),
          icon: 'spinner',
          disabled: true,
        };
    }
  }, [
    readOnly,
    runButtonState,
    isOperationalCommunity,
    simulationLaunch,
    simulationPause,
    simulationResume,
    t,
  ]);

  const stopButtonOptions = useMemo<TStopButtonOptions>(() => {
    switch (stopButtonState) {
      case 'stop':
        return {
          icon: 'stop',
          onClick: simulationStop,
          disabled: readOnly,
        };
      case 'disabled':
        return {
          icon: 'stop',
          disabled: true,
        };
    }
  }, [readOnly, simulationStop, stopButtonState]);

  return {
    runButtonState,
    stopButtonState,
    runButtonOptions,
    stopButtonOptions,
    simulationLaunch,
    simulationPause,
    simulationResume,
    simulationStop,
  };
}
