import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import {
  useScmAccumulatedBillsDifferencesCommunityQuery,
  useScmAccumulatedBillsDifferencesQuery,
  useScmAccumulatedKpiDifferencesQuery,
  useScmProfileResultsQuery,
} from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import {
  selectSelectedEndDatetime,
  selectSelectedMemberUuid,
  selectSelectedStartDatetime,
} from 'src/redux/application/application.selectors';
import { selectActiveConfigurationJobUuid } from 'src/redux/configuration/configuration.selectors';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { UTCMoment } from 'src/utils/UTCMoment';

export type TBillsFees = {
  assistanceMonthlyFee: number;
  assistanceMonthlyFeePercent: number;
  fixedMonthlyFee: number;
  fixedMonthlyFeePercent: number;
  taxesSurcharges: number;
  taxesSurchargesPercent: number;
  marketplaceMonthlyFee: number;
  marketplaceMonthlyFeePercent: number;
  gridFees: number;
  gridFeesPercent: number;
  serviceMonthlyFee: number;
  serviceMonthlyFeePercent: number;
  energyCargoFee: number;
  energyCargoFeePercent: number;
  contractedPowerMonthlyFee: number;
  contractedPowerMonthlyFeePercent: number;
  contractedPowerCargoMonthlyFee: number;
  contractedPowerCargoMonthlyFeePercent: number;
};

export type TBills = {
  baseEnergyBill: number;
  boughtFromGrid: number;
  boughtFromGridPercent: number;
  earnedFromGrid: number;
  soldToGrid: number;
  spentToGrid: number;
  earnedFromCommunity: number;
  soldToCommunity: number;
  spentToCommunity: number;
  boughtFromCommunity: number;
  boughtFromCommunityPercent: number;
  gsyEnergyBill: number;
  gsyEnergyBillExclRevenue: number;
  gsyEnergyBillExclRevenueWithoutFees: number;
  homeBalance: number;
  homeBalanceKwh: number;
  savings: number;
  gsyEnergyBillExclRevenueWithoutFeesPercent: number;
  energyBenchmark: number;
  savingsVergy: number;
  gsyTotalBenefit: number;
  fees: TBillsFees;
  importGridFees: number;
};

export type TBillsCommunity = Record<string, { name: string; bills: TBills }>;
export type TKpis = {
  totalGsyECost: number;
  totalFitRevenue: number;
  totalBaseEnergyCost: number;
  totalEnergyDemandedKwh: number;
  totalEnergyProducedKwh: number;
  totalSelfConsumptionKwh: number;
  totalGsyECostExclRevenue: number;
  totalBaseEnergyCostExclRevenue: number;
  assetEnergyRequirementsKwh: Record<string, number>;
  areaEnergyRequirementsKwh: number;
  selfSufficiency: number;
  selfConsumption: number;
  carbonGeneratedGsyG: number;
  carbonGeneratedBaseCaseG: number;
  carbonSavingsG: number;
};

export type TProfileResults = {
  kpi: Array<{
    simulation_time: string;
    energy_demanded_kwh: number;
    energy_produced_kwh: number;
  }>;
  billsEnergy: Array<{
    simulation_time: string;
    sold_to_grid: number;
  }>;
  carbonEmissions: {
    carbon_generated_gsy_g: number;
    carbon_savings_g: number;
  };
};

export type TUseResults = {
  minDate: Date;
  maxDate: Date;
  startOfMaxDate: string;
  endOfMaxDate: string;
  profileResults: TProfileResults;
  billsDifferences: TBills;
  billsDifferencesCommunity: TBillsCommunity;
  kpiDifferences: TKpis;
  isLoading: boolean;
};

export const useResults = (): TUseResults => {
  const selectedMemberUuid = useSelector(selectSelectedMemberUuid);
  const activeJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const selectedStartDatetime = useSelector(selectSelectedStartDatetime);
  const selectedEndDatetime = useSelector(selectSelectedEndDatetime);

  const {
    communityArea,
    settings,
    communityAdvancedSettings,
    isOperationalCommunity,
  } = useConfiguration();

  const areaUuid = useMemo(
    () => (!!selectedMemberUuid ? selectedMemberUuid : communityArea?.uuid),
    [communityArea, selectedMemberUuid],
  );

  const isQueryParameters =
    !!activeJobUuid && !!areaUuid && !!selectedStartDatetime && !!selectedEndDatetime;

  const {
    data: profileResultsResponse,
    loading: loadingProfileResults,
  } = useScmProfileResultsQuery({
    fetchPolicy: 'cache-first',
    skip: !isQueryParameters,
    variables: {
      jobId: activeJobUuid!,
      uuid: areaUuid!,
      startTime: selectedStartDatetime,
      endTime: selectedEndDatetime,
    },
  });
  const profileResults: TProfileResults = useMemo(
    () =>
      !!profileResultsResponse?.scmProfileResults?.kpi &&
      !!profileResultsResponse?.scmProfileResults?.billsEnergy &&
      !!profileResultsResponse?.scmProfileResults?.carbonEmissions
        ? {
            kpi: JSON.parse(profileResultsResponse.scmProfileResults.kpi),
            billsEnergy: JSON.parse(profileResultsResponse.scmProfileResults.billsEnergy),
            carbonEmissions: JSON.parse(profileResultsResponse.scmProfileResults.carbonEmissions),
          }
        : {
            kpi: [],
            billsEnergy: [],
            carbonEmissions: { carbon_generated_gsy_g: 0, carbon_savings_g: 0 },
          },
    [
      profileResultsResponse?.scmProfileResults?.kpi,
      profileResultsResponse?.scmProfileResults?.billsEnergy,
      profileResultsResponse?.scmProfileResults?.carbonEmissions,
    ],
  );

  const {
    data: billsDifferencesResponse,
    loading: loadingBills,
  } = useScmAccumulatedBillsDifferencesQuery({
    fetchPolicy: 'cache-first',
    skip: !isQueryParameters,
    variables: {
      jobId: activeJobUuid!,
      uuid: areaUuid!,
      startTime: selectedStartDatetime,
      endTime: selectedEndDatetime,
    },
  });
  const billsDifferences: TBills = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bills = objectCamelCase<any>(
      !!billsDifferencesResponse?.scmAccumulatedBillsDifferences
        ? JSON.parse(billsDifferencesResponse.scmAccumulatedBillsDifferences)
        : {},
    );
    if (Object.keys(bills).length > 0) {
      bills.fees = objectCamelCase<TBillsFees>(bills.fees);
    }
    return bills;
  }, [billsDifferencesResponse]);

  const {
    data: billsDifferencesCommunityResponse,
    loading: loadingBillsCommunity,
  } = useScmAccumulatedBillsDifferencesCommunityQuery({
    fetchPolicy: 'cache-first',
    skip: !isQueryParameters,
    variables: {
      jobId: activeJobUuid!,
      startTime: selectedStartDatetime,
      endTime: selectedEndDatetime,
    },
  });
  const billsDifferencesCommunity: TBillsCommunity = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bills = !!billsDifferencesCommunityResponse?.scmAccumulatedBillsDifferencesCommunity
      ? JSON.parse(billsDifferencesCommunityResponse.scmAccumulatedBillsDifferencesCommunity)
      : {};

    return Object.keys(bills).reduce((acc, key) => {
      acc[key] = { name: bills[key].name, bills: objectCamelCase<TBills>(bills[key].bills) };
      return acc;
    }, {} as TBillsCommunity);
  }, [billsDifferencesCommunityResponse]);

  const {
    data: kpiDifferencesResponse,
    loading: loadingKpis,
  } = useScmAccumulatedKpiDifferencesQuery({
    fetchPolicy: 'cache-first',
    skip: !isQueryParameters,
    variables: {
      jobId: activeJobUuid!,
      uuid: areaUuid!,
      startTime: selectedStartDatetime,
      endTime: selectedEndDatetime,
    },
  });
  const kpiDifferences: TKpis = useMemo(() => {
    const kpis = objectCamelCase<TKpis>(
      !!kpiDifferencesResponse?.scmAccumulatedKpiDifferences
        ? JSON.parse(kpiDifferencesResponse?.scmAccumulatedKpiDifferences)
        : {},
    );
    return kpis;
  }, [kpiDifferencesResponse]);

  const minDate: Date = useMemo(() => {
    if (!!settings) return UTCMoment.utc(settings?.startDate).toDate();
    return UTCMoment.utc().toDate();
  }, [settings]);

  const maxDate: Date = useMemo(() => {
    if (isOperationalCommunity)
      return UTCMoment.fromBackend(Date.now())
        .subtract(communityAdvancedSettings?.scmCnHoursOfDelay, 'hour')
        .toDate();
    if (!!settings) return UTCMoment.utc(settings?.endDate).subtract(1, 'day').toDate();
    return UTCMoment.utc().toDate();
  }, [communityAdvancedSettings, isOperationalCommunity, settings]);
  const startOfMaxDate: string = useMemo(() => {
    return UTCMoment.utc(maxDate).startOf('day').toDate().toISOString();
  }, [maxDate]);
  const endOfMaxDate: string = useMemo(() => {
    return UTCMoment.utc(maxDate).endOf('day').toDate().toISOString();
  }, [maxDate]);

  const isLoading = useMemo(
    () => loadingProfileResults || loadingBills || loadingBillsCommunity || loadingKpis,
    [loadingProfileResults, loadingBills, loadingBillsCommunity, loadingKpis],
  );

  return {
    minDate,
    maxDate,
    startOfMaxDate,
    endOfMaxDate,
    profileResults,
    billsDifferences,
    billsDifferencesCommunity,
    kpiDifferences,
    isLoading,
  };
};
