import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseInput } from 'src/components/BaseInput';
import { CustomScrollbar } from 'src/components/CustomScrollbar';
import { FileAttachment } from 'src/components/MapSidebar/components/MessagingPortal/FileAttachment';
import { MessagingPortalForm } from 'src/components/MapSidebar/components/MessagingPortalForm';
import { TextBrick } from 'src/components/TextBrick';
import {
  ListCommunityNotificationOutput,
  useListCommunityNotificationsLazyQuery,
} from 'src/graphql';
import { useAuth } from 'src/hooks/useAuth';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { setUnreadMessageCount } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { TAsset } from 'src/typings/configuration.types';
import { UTCMoment } from 'src/utils/UTCMoment';
import { v4 } from 'uuid';

import s from './MessagingPortal.module.scss';

type MessagingPortalProps = {
  hostAssetUuid: TAsset['uuid'];
  theme?: 'dark' | 'light';
  formId?: React.MutableRefObject<string>;
  readOnly?: boolean;
};

export const MessagingPortal: React.FC<MessagingPortalProps> = ({}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchQuery, __setDebouncedSearchQuery] = useState(searchValue);
  const searchTranslate = t('SEARCH');
  const formId = useRef(v4());

  const configUuid = useSelector(selectActiveConfigurationUuid);
  const initialNotificationsState: ListCommunityNotificationOutput = {
    notifications: [],
    totalCount: 0,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDebouncedSearchQuery = useCallback(
    debounce((val) => {
      __setDebouncedSearchQuery(val);
    }, 400),
    [],
  );

  useEffect(() => {
    setDebouncedSearchQuery(searchValue);
  }, [searchValue, setDebouncedSearchQuery]);

  const [, setListCommunityNotifications] = useState<ListCommunityNotificationOutput>(
    initialNotificationsState,
  );
  const { isMember } = useAuth();
  const [
    fetchCommunityNotifications,
    { data: CommunityNotifications },
  ] = useListCommunityNotificationsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const notifications = data.listCommunityNotifications?.notifications;
      const totalCount = data.listCommunityNotifications?.totalCount;
      const unreadCount = data.listCommunityNotifications?.unreadCount;
      // update the state with the fetched notifications
      setListCommunityNotifications({
        notifications,
        totalCount,
      });
      dispatch(setUnreadMessageCount(unreadCount));
    },
  });

  const notificationsList = useMemo(() => {
    return CommunityNotifications?.listCommunityNotifications?.notifications || [];
  }, [CommunityNotifications]);

  const filteredNotifications = useMemo(
    () =>
      notificationsList.filter((item) => {
        return item?.title?.toLowerCase().includes(debouncedSearchQuery.toLowerCase());
      }),
    [debouncedSearchQuery, notificationsList],
  );

  const handleSendMessageButton = () => {
    fetchCommunityNotifications({
      variables: {
        configUuid: configUuid,
      },
    });
  };

  useEffect(() => {
    fetchCommunityNotifications({
      variables: {
        configUuid: configUuid,
      },
    });
  }, [fetchCommunityNotifications, configUuid]);

  return (
    <div className={s.container}>
      {!isMember && (
        <>
          <div className={s.rowWrapper}>
            <div className={s.messageHeader}>
              <h3>{t('navigation.SENT_MESSAGES')}</h3>
            </div>
            <div className={s.searchAndSelect}>
              <BaseInput
                type="text"
                name="search"
                autoComplete="off"
                className={s.input}
                inputHeight="2"
                theme="filled-gray"
                placeholder={searchTranslate}
                value={searchValue}
                onChange={({ value }) => setSearchValue(`${value}`)}
              />
            </div>
            <div className={s.subHeading}>
              <h4>{t('RECENT_MESSAGES')}</h4>
            </div>
            <CustomScrollbar className={classnames(s.messageListWrapper)}>
              <div className={s.messageContainer}>
                {filteredNotifications && filteredNotifications.length == 0 && (
                  <div className={s.noMessages}>
                    <TextBrick>{t('NO_MESSAGES')}</TextBrick>
                  </div>
                )}
                {filteredNotifications && filteredNotifications.length > 0 && (
                  <>
                    {filteredNotifications.map((item, index) => (
                      <div
                        className={classnames(s.messageItem, {
                          [s.newMesssage]: item?.status == 'UNREAD',
                        })}
                        key={index}>
                        <h4>
                          {item?.title}{' '}
                          <span>{UTCMoment.utc(item?.createdAt).format('DD MMM yyyy')}</span>
                        </h4>
                        <p>{item?.message}</p>
                        {item?.uploadedFiles && item?.uploadedFiles?.length > 0 && (
                          <FileAttachment item={item} />
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </CustomScrollbar>
          </div>
          <div className={s.rowWrapper}>
            <div className={s.messageHeader}>
              <h3>{t('navigation.NEW_MESSAGE')}</h3>
            </div>
            <CustomScrollbar className={classnames(s.formWrapper)}>
              <div className={s.messagingPortalFormContainer}>
                <MessagingPortalForm
                  id={formId.current}
                  onFormSubmit={handleSendMessageButton}
                  disableLocationField={false}
                />
              </div>
            </CustomScrollbar>
          </div>
        </>
      )}
      {isMember && (
        <>
          <div className={s.rowFullWrapper}>
            <div className={s.messageHeader}>
              <h3>{t('INBOX')}</h3>
            </div>
            <div className={s.searchAndSelect}>
              <BaseInput
                type="text"
                name="search"
                className={s.input}
                inputHeight="2"
                theme="filled-gray"
                placeholder={searchTranslate}
                autoComplete="off"
                value={searchValue}
                onChange={({ value }) => setSearchValue(`${value}`)}
              />
            </div>
            <div className={s.subHeading}>
              <h4>{t('RECENT_MESSAGES')}</h4>
            </div>
            <CustomScrollbar className={classnames(s.messageListWrapper)}>
              <div className={s.messageContainer}>
                {filteredNotifications && filteredNotifications.length == 0 && (
                  <div className={s.noMessages}>
                    <TextBrick>{t('NO_MESSAGES')}</TextBrick>
                  </div>
                )}
                {filteredNotifications && filteredNotifications.length > 0 && (
                  <>
                    {filteredNotifications.map((item, index) => (
                      <div
                        className={classnames(s.messageItem, {
                          [s.newMesssage]: item?.status == 'UNREAD',
                        })}
                        key={index}>
                        <h4>
                          {item?.title}{' '}
                          <span>{UTCMoment.utc(item?.createdAt).format('DD MMM yyyy')}</span>
                        </h4>
                        <p>{item?.message}</p>
                        {item?.uploadedFiles && item?.uploadedFiles?.length > 0 && (
                          <FileAttachment item={item} />
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </CustomScrollbar>
          </div>
        </>
      )}
    </div>
  );
};
