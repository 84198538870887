import React, { useCallback, useContext, useEffect, useState } from 'react';

import { throttle } from 'lodash';
import { useSelector } from 'react-redux';
import { ModalCommunitySummary } from 'src/components/ModalCommunitySummary';
import { Popup } from 'src/components/WorldMap/components/Popup';
import s from 'src/components/WorldMapSelectedCommunityAssets/WorldMapSelectedCommunityAssets.module.scss';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { selectConfigurationCenter } from 'src/redux/configuration/configuration.selectors';

export const WorldMapCommunityPopup: React.FC = React.memo(() => {
  const configurationCenter = useSelector(selectConfigurationCenter);

  const { mapService } = useContext(WorldMapContext);

  const [summaryModalPos, setSummaryModalPos] = useState({
    lng: configurationCenter?.lng,
    lat: configurationCenter?.lat,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateCommunitySummaryModalPos = useCallback(
    throttle(() => {
      const communityRing = mapService?.threeboxController.getCommunityRingObject();

      if (communityRing && configurationCenter) {
        const summaryAdjustedPos = communityRing.calculateAdjustedPosition(
          [configurationCenter.lng, configurationCenter.lat],
          { x: -2.1, y: 0, z: 0 },
        );

        setSummaryModalPos({
          lat: summaryAdjustedPos[1],
          lng: summaryAdjustedPos[0],
        });
      }
    }, 100),
    [configurationCenter, mapService?.threeboxController, summaryModalPos],
  );

  useEffect(() => {
    mapService?.map.on('load', calculateCommunitySummaryModalPos);
    mapService?.map.on('move', calculateCommunitySummaryModalPos);
    return () => {
      mapService?.map.off('load', calculateCommunitySummaryModalPos);
      mapService?.map.off('move', calculateCommunitySummaryModalPos);
    };
  }, [calculateCommunitySummaryModalPos, mapService]);

  return (
    <Popup
      lat={summaryModalPos.lat}
      lng={summaryModalPos.lng}
      className={s.rootMarker}
      higherZIndex>
      <ModalCommunitySummary />
    </Popup>
  );
});
