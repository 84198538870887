import React from 'react';

import classNames from 'classnames';
import { TAssetPopupProps } from 'src/components/AssetPopup/AssetPopup.types';
import { BaseIcon } from 'src/components/BaseIcon';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { get2dAssetIcon } from 'src/utils/assetIcons';
import { formatter } from 'src/utils/formatter';
import { flattenChildren } from 'src/utils/general';

import s from './AssetPopup.module.scss';

export const AssetPopup: React.FC<TAssetPopupProps> = ({ showMemberAddress }) => {
  const { assetTypes, area } = useConfiguration();

  return (
    <div className={classNames(s.paddingAround, s.background, s.wrapper, s.modalTooltip)}>
      <header className={s.header}>
        <div className={s.typeIcon}>
          <BaseIcon icon={'house'} size={12} />
        </div>
        <div className={s.headerTextWrapper}>
          <h3 className={s.title}>{area?.name}</h3>
          {showMemberAddress && <span className={s.address}>{area?.address}</span>}
        </div>
      </header>
      <ul className={s.assetsList}>
        {assetTypes(flattenChildren(area?.children)).map((asset) => (
          <li key={asset.type}>
            <div className={s.assetSquareBtn}>
              <BaseIcon icon={get2dAssetIcon(asset.type)} size={16.25} />
              <label className={s.assetsAmount}>{formatter.prefixWithZeros(asset.count)}</label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
