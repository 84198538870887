import { useCallback, useContext } from 'react';

import * as turf from '@turf/turf';
import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ZOOM } from 'src/constants/application';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { TAreaOutput, TAreaUuidToArea, useConfiguration } from 'src/hooks/useConfiguration';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import {
  configurationReset,
  setActiveConfigurationJobUuid,
} from 'src/redux/configuration/configuration.slice';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { TLngLatArray } from 'src/typings/base-types';
import { ELsKey, ls } from 'src/utils/localStorage';
import { convertLngLat } from 'src/utils/worldMap/helpers';

type TUseConfiguration = {
  discardCurrentConfiguration: (options?: { zoomOut?: boolean }) => void;
  zoomIntoConfiguration: (values?: {
    areaUuidToArea: TAreaUuidToArea;
    communityArea: TAreaOutput;
  }) => void;
};

/**
 *
 * @param options Override selectors values by options
 */
export function useMapNavigation(): TUseConfiguration {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { mapService } = useContext(WorldMapContext);
  const location = useAppLocation();
  const { pathname } = location;
  const isUserInvitationFlow = !isLoggedIn && pathname.includes('/singularity-map/results');

  const { areaUuidToArea, communityArea } = useConfiguration();

  const discardCurrentConfiguration = useCallback(
    (options?: { zoomOut?: boolean }) => {
      batch(() => {
        dispatch(updateSelectedLocation(null));
        dispatch(configurationReset());
        dispatch(setActiveConfigurationJobUuid(undefined));
      });
      if (isUserInvitationFlow) {
        ls.set(ELsKey.INVITE_LINK, pathname);
      }
      history.push(routesConfig.scmMap());

      if (options?.zoomOut) {
        const viewport = mapService?.getViewport();

        if (viewport) {
          mapService?.flyTo(viewport, { specificZoom: ZOOM.QUIT_COMMUNITY, speed: 2.5 });
        }
      }
    },
    [dispatch, history, mapService, isUserInvitationFlow, pathname],
  );

  const zoomIntoConfiguration = useCallback(
    (values?: { areaUuidToArea: TAreaUuidToArea; communityArea: TAreaOutput }) => {
      // If values are not provided, use the current values from cache
      const areaValues = values?.areaUuidToArea || areaUuidToArea;
      const community = values?.communityArea || communityArea;

      if (Object.keys(areaValues).length === 0) return;
      const coordsArray = Object.values(areaValues).reduce(
        (acc: TLngLatArray[], item: TAreaOutput) => {
          const { geoTagLocation } = item;
          if (!geoTagLocation?.length) return acc;
          acc.push(geoTagLocation as TLngLatArray);
          return acc;
        },
        [],
      );

      if (!coordsArray.length) return;

      if (coordsArray.length === 1) {
        coordsArray.push(coordsArray[0]);
      }

      const features = turf.points(coordsArray);
      const center = turf.center(features).geometry.coordinates;
      const configurationCenter = convertLngLat(center as TLngLatArray);

      mapService?.threeboxController.setCommunityAssets(
        areaValues,
        community ?? undefined,
        configurationCenter,
      );

      const maxDist =
        Math.sqrt(
          Math.max(
            ...coordsArray.map(([x, y]) => {
              const rx = Math.abs(center[0] - x);
              const ry = Math.abs(center[1] - y);
              return rx * rx + ry * ry;
            }),
          ),
        ) + 0.003;

      const bbox = [
        center[0] - maxDist,
        center[1] - maxDist,
        center[0] + maxDist,
        center[1] + maxDist,
      ];

      mapService?.flyTo(
        {
          bbox: bbox as [number, number, number, number],
        },
        {
          speed: 2.5,
        },
      );
    },
    [mapService, areaUuidToArea, communityArea],
  );

  return {
    discardCurrentConfiguration,
    zoomIntoConfiguration,
  };
}
