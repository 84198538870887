import React, { useCallback, useContext, useEffect, useState } from 'react';

import { throttle } from 'lodash';
import { useSelector } from 'react-redux';
import { Popup } from 'src/components/WorldMap/components/Popup';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { selectConfigurationCenter } from 'src/redux/configuration/configuration.selectors';
import { convertLngLat } from 'src/utils/worldMap/helpers';

import s from './WorldMapSelectedCommunityAssets.module.scss';
import { TWorldMapSelectedCommunityAssetsProps } from './WorldMapSelectedCommunityAssets.types';

export const WorldMapSelectedCommunityAssets: React.FC<TWorldMapSelectedCommunityAssetsProps> = React.memo(
  ({ disableInteraction }) => {
    const configurationCenter = useSelector(selectConfigurationCenter);

    const { mapService } = useContext(WorldMapContext);
    const { isOperationalCommunity, communityMembers, area } = useConfiguration();

    const [summaryModalPos, setSummaryModalPos] = useState({
      lng: configurationCenter?.lng,
      lat: configurationCenter?.lat,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const calculateCommunitySummaryModalPos = useCallback(
      throttle(() => {
        const communityRing = mapService?.threeboxController.getCommunityRingObject();

        if (communityRing && configurationCenter) {
          const summaryAdjustedPos = communityRing.calculateAdjustedPosition(
            [configurationCenter.lng, configurationCenter.lat],
            { x: -2.1, y: 0, z: 0 },
          );

          setSummaryModalPos({
            lat: summaryAdjustedPos[1],
            lng: summaryAdjustedPos[0],
          });
        }
      }, 100),
      [configurationCenter, mapService?.threeboxController, summaryModalPos],
    );

    useEffect(() => {
      mapService?.map.on('load', calculateCommunitySummaryModalPos);
      mapService?.map.on('move', calculateCommunitySummaryModalPos);
      return () => {
        mapService?.map.off('load', calculateCommunitySummaryModalPos);
        mapService?.map.off('move', calculateCommunitySummaryModalPos);
      };
    }, [calculateCommunitySummaryModalPos, mapService]);
    return (
      <>
        {communityMembers.map((member) => {
          if (!member?.geoTagLocation) return null;

          const lngLat = convertLngLat(member.geoTagLocation as [number, number]);
          const isSelected = area?.uuid === member.uuid;

          return (
            <Popup
              key={member.uuid}
              lat={lngLat.lat}
              lng={lngLat.lng}
              className={s.marker}
              higherZIndex={true}>
              {isOperationalCommunity && !isSelected && (
                <button
                  type="button"
                  className={s.houseOwnerPopup}
                  disabled={disableInteraction}
                  onClick={() => null}>
                  {!isSelected && (
                    <div
                      className={s.avatar}
                      style={{
                        backgroundColor: '#a0a0ac',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      {member?.name?.charAt(0).toUpperCase()}
                    </div>
                  )}

                  {!isSelected && <span>{member.name}</span>}
                  {!isSelected && (
                    <svg
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 0L4.37253 6.12154C5.17017 7.23824 6.82983 7.23824 7.62747 6.12155L12 0L0 0Z"
                        fill="#211E21"
                        fillOpacity="0.7"
                      />
                    </svg>
                  )}
                </button>
              )}
            </Popup>
          );
        })}
      </>
    );
  },
);
