import { useCallback, useMemo } from 'react'

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  AreaOutput,
  ConfigType,
  ConfigurationOutput,
  Currencies,
  MeterDataStream,
  ProgressInfo,
  ScmAdvancedSettingsOutput,
  ScmCommunityMember,
  SettingsOutput,
  useGetOperationalQuery,
  useListMeterDataStreamsQuery,
  useMarketSummaryLazyQuery,
  useReadConfigurationQuery,
  useGetSimulationGlobalResultsQuery
} from 'src/graphql';
import { useAuth } from 'src/hooks/useAuth';
import { selectSelectedMemberUuid } from 'src/redux/application/application.selectors';
import { setSelectedMemberUuid } from 'src/redux/application/application.slice';
import { selectUsername } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectSelectedAreaUuid,
  selectSelectedAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import {
  setActiveConfigurationJobUuid,
  setActiveConfigurationUuid,
  setRawCommunitySimulationResults,
  setGlobalSimulationResults,
} from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { formatter } from 'src/utils/formatter';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

export type TAreaOutput = AreaOutput & { parentUuid?: string };
export type TAreaUuidToArea = Record<string, TAreaOutput>;

type TUseConfiguration = {
  configuration: ConfigurationOutput | null;
  communityAdvancedSettings: ScmAdvancedSettingsOutput | null;
  settings: SettingsOutput | null;
  currencyName: Currencies;
  currencySymbol: string;
  configType: ConfigType;
  communityArea: TAreaOutput | null;
  communityAreas: AreaOutput[];
  area: AreaOutput | null;
  assets: AreaOutput[];
  areaUuidToArea: TAreaUuidToArea;
  meterDataStreams: MeterDataStream[] | null;
  activeJobUuid: string | null;
  isLoadingReadConfiguration: boolean;
  resultsStatus: string | null;
  resultsProgress: ProgressInfo;
  isOperationalCommunity: boolean;
  isSimulationCommunity: boolean;
  communityLogo: string;
  communityMembers: ScmCommunityMember[];
  selectedMember: ScmCommunityMember | null;
  selectedAsset: TAreaOutput | null;
  isCommunityAreaRepresentationEmpty: boolean;
  skipReadConfiguration: boolean;
  refetchReadConfiguration: () => void;
  startDate: string;
  endDate: string;
  refetchMarketSummary: () => void;
  configurationToAreaUuidToArea: (
    configuration: ConfigurationOutput,
  ) => { areaUuidToArea: TAreaUuidToArea; communityArea: TAreaOutput };
  assetTypes: (areas: TAreaOutput[]) => Array<{ type: string; count: number }>;
};

const TODAY = UTCMoment.utc();
const TOMORROW = UTCMoment.utc().add(7, 'day');

export const useConfiguration = (): TUseConfiguration => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const configUuid = useSelector(selectActiveConfigurationUuid);
  const jobId = useSelector(selectActiveConfigurationJobUuid)
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const selectedMemberUuid = useSelector(selectSelectedMemberUuid);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const userName = useSelector(selectUsername);

  const { isMember } = useAuth();

  const skipReadConfiguration = useMemo(() => !configUuid || configUuid === 'NEW_COMMUNITY', [
    configUuid,
  ]);

  /* Configuration */
  const {
    data,
    loading: isLoadingReadConfiguration,
    refetch: refetchReadConfiguration,
  } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: skipReadConfiguration,
    variables: { uuid: configUuid! },
    onCompleted: (data) => {
      if (!!data?.readConfiguration?.simulationResults) {
        dispatch(setActiveConfigurationJobUuid(data.readConfiguration.simulationResults));

        if (isMember && !!configuration?.scenarioData?.homeInfo?.scmHomeDetails) {
          const communityMember = configuration.scenarioData.homeInfo.scmHomeDetails.find(
            (member) => member?.email === userName,
          );
          if (!!communityMember?.uuid) dispatch(setSelectedMemberUuid(communityMember.uuid));
        }
      }
    },
  });

  useGetOperationalQuery({
    fetchPolicy: 'cache-first',
    skip: !isMember,
    onCompleted: (data) => {
      if (!!data.getScmMemberOperationalUuid) {
        const configurationUuid = data.getScmMemberOperationalUuid;
        dispatch(setActiveConfigurationUuid(configurationUuid));
        history.push(routesConfig.scmMapResults(configurationUuid));
      }
    },
  });

  useGetSimulationGlobalResultsQuery({
    fetchPolicy: 'no-cache',
    skip: !jobId,
    variables: { jobId: jobId! },
    onCompleted: (data) => {
      if (!!data.simulationGlobalResults) {
        dispatch(setGlobalSimulationResults(data.simulationGlobalResults));
      }
    },
  });

  const configuration: ConfigurationOutput | null = useMemo(
    () => data?.readConfiguration as ConfigurationOutput | null,
    [data],
  );
  const configType: ConfigType = useMemo(() => configuration?.type || ConfigType.Collaboration, [
    configuration,
  ]);
  const settings: SettingsOutput | null = useMemo(
    () => configuration?.settingsData as SettingsOutput | null,
    [configuration],
  );
  const currencyName: Currencies = useMemo(() => settings?.currency || Currencies.Eur, [settings]);
  const currencySymbol: string = useMemo(() => formatter.getCurrencySymbol(currencyName), [
    currencyName,
  ]);
  const communityLogo: string = useMemo(() => configuration?.settingsData?.logo || '', [
    configuration?.settingsData?.logo,
  ]);
  const isOperationalCommunity: boolean = useMemo(
    () => configuration?.type === ConfigType.CanaryNetwork || false,
    [configuration],
  );
  const isSimulationCommunity: boolean = useMemo(
    () => configuration?.type === ConfigType.Collaboration || false,
    [configuration],
  );
  const activeJobUuid: string | null = useMemo(() => configuration?.simulationResults || null, [
    configuration,
  ]);
  const resultsStatus: string | null = useMemo(() => configuration?.resultsStatus || null, [
    configuration?.resultsStatus,
  ]);
  const resultsProgress: ProgressInfo = useMemo(
    () =>
      resultsStatus === 'finished'
        ? { etaSeconds: 0, elapsedTimeSeconds: 0, percentageCompleted: 100 }
        : { etaSeconds: 0, elapsedTimeSeconds: 0, percentageCompleted: 0 },
    [resultsStatus],
  );
  const communityArea: TAreaOutput | null = useMemo(() => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration.scenarioData.latest.serialized)
      : null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const area = objectCamelCase<any>(scenario?.children.find((child) => child.type === 'Area'));
    return { ...area, parentUuid: scenario?.uuid };
  }, [configuration]);
  const communityAreas: AreaOutput[] = useMemo(() => {
    const areas =
      communityArea?.children?.map((
        area, // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) => (area ? objectCamelCase<any>(area) : area)) || [];
    return areas;
  }, [communityArea]);
  const isCommunityAreaRepresentationEmpty = useMemo(() => !!configuration && !communityArea, [
    communityArea,
    configuration,
  ]);
  const area = useMemo(() => {
    const areaUuid = selectedAreaUuid || selectedMemberUuid;
    const selectedArea =
      communityArea?.children?.find((area): area is AreaOutput => area?.uuid === areaUuid) || null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return selectedArea ? objectCamelCase<any>(selectedArea) : null;
  }, [communityArea, selectedAreaUuid, selectedMemberUuid]);
  const assets: AreaOutput[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return area && 'children' in area && area.children
      ? area.children.map((asset) => (asset ? objectCamelCase<any>(asset) : asset))
      : [];
  }, [area]);
  const areaUuidToArea: TAreaUuidToArea = useMemo(() => {
    return communityAreas.reduce((acc, area) => {
      acc[area.uuid] = { ...area, parentUuid: communityArea?.uuid };
      return acc;
    }, {} as TAreaUuidToArea);
  }, [communityAreas, communityArea]);
  const communityMembers: ScmCommunityMember[] = useMemo(() => {
    const members = (configuration?.scenarioData?.homeInfo?.scmHomeDetails ?? []).filter(
      (member): member is ScmCommunityMember => member !== null,
    );
    return members;
  }, [configuration]);
  const selectedMember: ScmCommunityMember | null = useMemo(() => {
    const areaUuid = selectedMemberUuid || selectedAreaUuid;
    return communityMembers.find((member) => member.uuid === areaUuid) || null;
  }, [communityMembers, selectedMemberUuid, selectedAreaUuid]);
  const selectedAsset: AreaOutput | null = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return assets.find((asset) => asset?.uuid === selectedAssetUuid) || null;
  }, [assets, selectedAssetUuid]);
  const startDate: string = useMemo(
    () => settings?.startDate || TODAY.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
    [settings?.startDate],
  );
  const endDate: string = useMemo(
    () => settings?.endDate || TOMORROW.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
    [settings?.endDate],
  );
  const communityAdvancedSettings: ScmAdvancedSettingsOutput | null = useMemo(
    () => configuration?.communityAdvancedSettings || null,
    [configuration],
  );

  /* MeterDataStreams */
  const { data: meterDataStreamsResponse } = useListMeterDataStreamsQuery({
    fetchPolicy: 'cache-first',
    skip: skipReadConfiguration,
    variables: { configUuid: configUuid! },
  });
  const meterDataStreams: MeterDataStream[] | null = useMemo(() => {
    const streams = !!meterDataStreamsResponse?.listMeterDataStreams
      ? meterDataStreamsResponse?.listMeterDataStreams.map((stream) => ({
          areaUuid: stream?.areaUuid || '',
          streamId: stream?.streamId || '',
        }))
      : null;
    return streams;
  }, [meterDataStreamsResponse]);

  /* Market Summary */
  const [readMarketSummary] = useMarketSummaryLazyQuery({
    fetchPolicy: 'network-only',

    onCompleted: (data) => {
      if (!!data?.scmProfileResults?.marketSummary) {
        const marketSummary = JSON.parse(data.scmProfileResults.marketSummary);

        dispatch(
          setRawCommunitySimulationResults({
            marketSummary: marketSummary.map((obj) =>
              objectCamelCase<Record<string, unknown>>(obj),
            ),
          }),
        );
      }
    },
    onError: (error) => {
      console.error('Error fetching market summary:', error);
    },
  });

  const refetchMarketSummary = useCallback(() => {
    if (
      !!activeJobUuid &&
      !!communityArea?.uuid &&
      !!settings?.startDate &&
      !!settings?.endDate &&
      !isOperationalCommunity
    ) {
      readMarketSummary({
        variables: {
          jobId: activeJobUuid!,
          uuid: communityArea!.uuid!,
          startTime: UTCMoment.utc(settings.startDate).toDate(),
          endTime: UTCMoment.utc(settings.endDate).toDate(),
        },
      });
    }
  }, [
    activeJobUuid,
    communityArea,
    readMarketSummary,
    settings?.endDate,
    settings?.startDate,
    isOperationalCommunity,
  ]);

  const configurationToAreaUuidToArea = useCallback((configuration: ConfigurationOutput) => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration?.scenarioData?.latest?.serialized || '{}')
      : null;
    const communityArea = scenario?.children?.find((area) => area.type === 'Area');

    const communityAreas =
      communityArea?.children?.map((
        area, // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) => (area ? objectCamelCase<any>(area) : area)) || [];

    const areaUuidToArea = communityAreas.reduce((acc, area) => {
      acc[area.uuid] = { ...area, parentUuid: communityArea?.uuid };
      return acc;
    }, {} as TAreaUuidToArea);
    return { communityArea, areaUuidToArea };
  }, []);

  const assetTypes = useCallback((areas: TAreaOutput[]): Array<{ type: string; count: number }> => {
    const groupedAssetsByType =
      areas.reduce((acc, asset) => {
        if (asset && asset.type) {
          acc[asset.type] = (acc[asset.type] || 0) + 1;
        }
        return acc;
      }, {} as Record<string, number>) || {};
    return Object.entries(groupedAssetsByType).map(
      ([type, count]) => ({ type, count } as { type: string; count: number }),
    );
  }, []);

  return {
    configuration,
    communityAdvancedSettings,
    settings,
    currencyName,
    currencySymbol,
    configType,
    communityArea,
    communityAreas,
    area,
    assets,
    areaUuidToArea,
    meterDataStreams,
    activeJobUuid,
    isLoadingReadConfiguration,
    resultsStatus,
    resultsProgress,
    isOperationalCommunity,
    isSimulationCommunity,
    communityLogo,
    communityMembers,
    selectedMember,
    selectedAsset,
    isCommunityAreaRepresentationEmpty,
    skipReadConfiguration,
    refetchReadConfiguration,
    startDate,
    endDate,
    refetchMarketSummary,
    configurationToAreaUuidToArea,
    assetTypes,
  };
};
