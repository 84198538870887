import React, { useEffect, useRef, useState } from 'react';

import mapboxgl, { MapboxOptions } from 'mapbox-gl';
import { useSelector } from 'react-redux';
import { EResultsTab } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';
import { Popup } from 'src/components/WorldMap/components/Popup';
import { LiteSummaryPopup } from 'src/features/Results/SingleCommunityResults/LiteSummaryPopup';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { selectActiveResultsTab } from 'src/redux/application/application.selectors';
import { setModalCommunitySummaryView } from 'src/redux/application/application.slice';
import {
  selectAssets,
  selectAssetsValues,
  selectConfigurationCenter,
} from 'src/redux/configuration/configuration.selectors';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { useAppDispatch } from 'src/redux/store';
import { WorldMapService } from 'src/services/map/map.service';
import { getPlaceTypeZoom } from 'src/utils/worldMap/helpers';

import s from './SingleCommunity.module.scss';

const MAP_SETTINGS: Partial<mapboxgl.MapboxOptions> = {
  center: [7, 48],
  zoom: 3,
  minPitch: 0,
  maxPitch: 50,
  style: process.env.REACT_APP_D3A_MAPBOX_STYLE,
};

export const LiteWorldMap: React.FC<TLiteWorldMapProps> = ({ className }) => {
  const dispatch = useAppDispatch();

  const mapboxRef = useRef<HTMLDivElement | null>(null);

  const [mapService, setMapService] = useState<WorldMapService | null>(null);

  const selectedLocation = useSelector(selectSelectedLocation);
  const assetsValues = useSelector(selectAssetsValues);
  const prevAssetsValues = useRef<TConfigurationState['assetsValues'] | null>(null);
  const assets = useSelector(selectAssets);
  const configurationCenter = useSelector(selectConfigurationCenter);
  const resultsTab = useSelector(selectActiveResultsTab);

  const { areaUuidToArea, communityArea, area, configuration } = useConfiguration();

  useEffect(() => {
    if (mapboxRef.current && !mapService) {
      mapboxgl.accessToken = process.env.REACT_APP_D3A_MAPBOX_KEY as string;

      if (selectedLocation && selectedLocation.lng && selectedLocation.lat) {
        MAP_SETTINGS.center = [selectedLocation.lng, selectedLocation.lat];
        MAP_SETTINGS.zoom = getPlaceTypeZoom(
          (selectedLocation.place_type && selectedLocation.place_type[0]) || '',
        );
      }
      const service = new WorldMapService(mapboxRef.current, MAP_SETTINGS as MapboxOptions);
      setMapService(service);
    }
  }, [mapService, selectedLocation, setMapService]);

  useEffect(() => {
    if (!mapService || !communityArea || !configurationCenter) return;

    prevAssetsValues.current = assetsValues;

    mapService.threeboxController.setMaxZoomGlobalViz(0);
    mapService?.threeboxController.setCommunityAssets(
      areaUuidToArea,
      communityArea,
      configurationCenter,
    );

    if (configurationCenter) mapService.flyTo(configurationCenter);
  }, [assets, mapService, assetsValues, areaUuidToArea, configurationCenter, communityArea]);

  useEffect(() => {
    dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));
  }, [dispatch]);

  return (
    <div className={className}>
      <div ref={mapboxRef} className={s.theMap} />
      {/* Community Popup */}
      {!!communityArea &&
        !!configurationCenter &&
        !!mapService &&
        !!mapboxRef.current &&
        resultsTab === EResultsTab.Community && (
          <Popup
            lat={configurationCenter.lat}
            lng={configurationCenter.lng}
            className={s.popupMini}
            higherZIndex={true}
            mapServiceProp={mapService}
            mapboxClassNames={[s.popupMini]}>
            <LiteSummaryPopup area={communityArea} name={configuration?.name || ''} icon="market" />
          </Popup>
        )}
      {/* Community Member */}
      {!!communityArea &&
        !!configurationCenter &&
        !!mapService &&
        !!mapboxRef.current &&
        resultsTab === EResultsTab.Home &&
        !!area && (
          <Popup
            lat={configurationCenter.lat}
            lng={configurationCenter.lng}
            className={s.popupMini}
            higherZIndex={true}
            mapServiceProp={mapService}
            mapboxClassNames={[s.popupMini]}>
            <LiteSummaryPopup area={area} name={area?.name || ''} icon="house" />
          </Popup>
        )}
    </div>
  );
};

type TLiteWorldMapProps = {
  className?: string;
};
