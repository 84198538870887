import React, { useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT } from 'src/features/Assets/constants';
import { SingleAsset } from 'src/features/Assets/SingleAsset';
import { AreaOutput, useUpdateConfigurationAreaMutation } from 'src/graphql';
import { ReadConfigurationDocument } from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useMapNavigation } from 'src/hooks/useMapNavigation';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import {
  selectSelectedAssetUuid,
  selectSelectedAreaUuid,
} from 'src/redux/configuration/configuration.selectors';
import {
  setSelectedAreaUuid,
  setSelectedAssetUuid,
  resetSimulationResults
} from 'src/redux/configuration/configuration.slice';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { areObjectsEqualByFields } from 'src/utils/general';
import { dispatchErrorToast, dispatchSuccessToast } from 'src/utils/toasts';

import s from './EditAsset.module.scss';

export const EditAsset: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const configUuid = useSelector(selectActiveConfigurationUuid);
  const areaUuid = useSelector(selectSelectedAreaUuid);
  const assetUuid = useSelector(selectSelectedAssetUuid);

  const { zoomIntoConfiguration } = useMapNavigation();
  const { selectedAsset, isOperationalCommunity } = useConfiguration();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newAsset, setNewAsset] = useState<AreaOutput | null>(selectedAsset);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [originalAsset, setOriginalAsset] = useState<AreaOutput | null>(selectedAsset);

  const [updateConfigurationArea] = useUpdateConfigurationAreaMutation({
    errorPolicy: 'none',
    refetchQueries: [
      { query: ReadConfigurationDocument, variables: { uuid: configUuid } },
    ],
    onCompleted: () => {
      if (!isOperationalCommunity) {
        dispatch(resetSimulationResults());
      }
      dispatchSuccessToast();
      setOriginalAsset(newAsset);
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  const onCancelClick = () => {
    batch(() => {
      dispatch(setSelectedAreaUuid(undefined));
      dispatch(setSelectedAssetUuid(undefined));
      zoomIntoConfiguration();
    });
  };

  const onChangeSingleAsset = (values: TValuesByFieldName) => {
    setNewAsset(values as AreaOutput);
  };

  const onClickSave = () => {
    if (assetUuid && configUuid && !!newAsset?.type) {
      const areaParams = ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT[newAsset.type]['params']({
        values: newAsset,
      });

      return updateConfigurationArea({
        variables: {
          configurationUuid: configUuid,
          areaUuid: assetUuid,
          areaParams,
        },
      });
    }
  };

  const canSave = useMemo(() => !areObjectsEqualByFields(newAsset, originalAsset), [
    newAsset,
    originalAsset,
  ]);

  return (
    <div className={s.editAsset}>
      <div className={s.container}>
        <header className={s.header}>
          <p>{t('navigation.EDIT_ASSET')}</p>
          <BaseButtonSquare size="2" icon="close" theme="flat-gray-light" onClick={onCancelClick} />
        </header>

        {assetUuid && areaUuid && <SingleAsset onChange={onChangeSingleAsset} asset={newAsset} />}
      </div>

      <BaseButton
        type="submit"
        className={s.saveSettings}
        form={'asset-settings'}
        disabled={!canSave}
        onClick={onClickSave}>
        Save
      </BaseButton>
    </div>
  );
};
