import React, { useMemo, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { TCreateMonthObjectResult } from 'src/features/Results/DashboardHeader/CustomResultHeader/SelectMonth/SelectMonth.helpers';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { selectSelectedStartDatetime } from 'src/redux/application/application.selectors';
import {
  setSelectedEndDatetime,
  setSelectedStartDatetime,
} from 'src/redux/application/application.slice';
import { selectSimulationStatus, selectGlobalSimulationResults } from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';
import { UTCMoment } from 'src/utils/UTCMoment';

import { createMonthObjectsArray } from './SelectMonth.helpers';
import s from './SelectMonth.module.scss';
import { TSelectMonthProps } from './SelectMonth.types';

export const SelectMonth: React.FC<TSelectMonthProps> = () => {
  const dispatch = useAppDispatch();

  const [opened, setOpened] = useState(false);

  const selectedStartDatetime = useSelector(selectSelectedStartDatetime);
  const status = useSelector(selectSimulationStatus);
  const globalResults = useSelector(selectGlobalSimulationResults);

  const { isOperationalCommunity, settings } = useConfiguration();

  const handleClickMonth = (item: TCreateMonthObjectResult) => {
    const startDatetime = UTCMoment.utc(item.startUtc)
      .startOf('month')
      .startOf('hour')
      .toISOString();
    const endDatetime = UTCMoment.utc(item.endUtc).endOf('month').endOf('hour').toISOString();

    dispatch(setSelectedStartDatetime(startDatetime));
    dispatch(setSelectedEndDatetime(endDatetime));
    setOpened(false);
  };

  const monthList = useMemo(() => {
    let startDatetime: string;
    let endDatetime: string;
    if (!!settings?.startDate && !!settings?.endDate) {
      startDatetime = settings.startDate;
      endDatetime = settings.endDate;
    } else {
      // default
      startDatetime = new Date().toISOString();
      endDatetime = new Date().toISOString();
    }

    if (isOperationalCommunity && !!globalResults?.currentMarket) {
      endDatetime = UTCMoment.utc(globalResults?.currentMarket).endOf('month').endOf('hour').toISOString();
    }

    return createMonthObjectsArray(
      UTCMoment.utc(startDatetime),
      UTCMoment.utc(endDatetime),
      status,
    ).reverse();
  }, [status, settings, globalResults, isOperationalCommunity]);

  const selectedMonth: TCreateMonthObjectResult = useMemo(() => {
    const month = monthList?.find(
      (item) =>
        UTCMoment.utc(item.startUtc).isSame(UTCMoment.utc(selectedStartDatetime), 'month') &&
        UTCMoment.utc(item.startUtc).isSame(UTCMoment.utc(selectedStartDatetime), 'year'),
    );

    return month || { title: '', startUtc: 0, endUtc: 0, isCurrent: false };
  }, [selectedStartDatetime, monthList]);

  return (
    <div
      className={classNames(s.container, {
        [s.opened]: opened,
      })}>
      <div className={s.valueButton} onClick={() => setOpened(!opened)}>
        <BaseButtonSquare icon="time" theme="flat-blue" iconSize={13} className={s.square} />
        <p>
          {isOperationalCommunity && selectedMonth?.isCurrent && <strong>Current - </strong>}
          {selectedMonth.title}{' '}
        </p>
        <div className={s.icon}>
          <BaseIcon icon="arrow" size={10} />
        </div>
      </div>
      <div className={s.dropdown}>
        {monthList.map((item, i) => (
          <button key={i} type="button" onClick={() => handleClickMonth(item)}>
            <span></span> {item.title}
          </button>
        ))}
      </div>
    </div>
  );
};
