import { useSelector } from 'react-redux';
import {
  selectProfilePicture,
  selectUsername,
  selectUserRole,
} from 'src/redux/auth/auth.selectors';
import { EUserRoles } from 'src/typings/base-types';

export type TUseAuth = {
  isMember: boolean;
  isAdmin: boolean;
  isManager: boolean;
  userRole?: EUserRoles;
  profilePicture: string;
  userName: string;
};

export const useAuth = (): TUseAuth => {
  const userRole = useSelector(selectUserRole);
  const profilePicture = useSelector(selectProfilePicture);
  const userName = useSelector(selectUsername);

  const isMember = userRole === EUserRoles.Researcher;
  const isManager = userRole === EUserRoles.ExchangeOperator;
  const isAdmin = userRole === EUserRoles.Admin || userRole === EUserRoles.ExchangeOperator;

  return { isMember, isAdmin, isManager, userRole, profilePicture, userName };
};
