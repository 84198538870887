import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { BaseButton } from 'src/components/BaseButton';
import { BaseClose } from 'src/components/BaseClose';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import { CustomScrollbar } from 'src/components/CustomScrollbar';
import { FormForgotPassword } from 'src/components/FormForgotPassword';
import { FormLogin } from 'src/components/FormLogin';
import { FormSignupSCM, useSignUpFormData } from 'src/components/FormSignup';
import { termsAndConditions } from 'src/components/ModalAuth/termsAndConditions.data';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { AuthToken, Maybe } from 'src/graphql';
import { closeModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';

import s from './AuthRegisterForms.module.scss';
import { TAuthRegisterFormsProps } from './AuthRegisterForms.types';

export const AuthRegisterForms: React.FC<TAuthRegisterFormsProps> = ({ onSuccess }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { formData, handleFieldChange } = useSignUpFormData();

  // PH-985-show default login view on mobile screens
  const [showLoginBlock, setShowLoginBlock] = useState<boolean>(true);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);

  const onLoginSuccess = useCallback<(data?: Maybe<AuthToken>) => void>(() => {
    onSuccess();
  }, [onSuccess]);

  return (
    <>
      <div>
        {!forgotPassword ? (
          <>
            <header className={s.formSwitches}>
              <BaseLink
                className={s.formSwitch}
                onClick={() => setShowLoginBlock(true)}
                isActive={showLoginBlock}
                theme="2">
                {t('LOGIN')}
              </BaseLink>
            </header>

            {showLoginBlock ? (
              <>
                <FormLogin
                  onSuccess={onLoginSuccess}
                  handleForgotPassword={() => setForgotPassword(true)}
                />
              </>
            ) : (
              <FormSignupSCM
                className={s.formSignup}
                onSuccess={onSuccess}
                {...{ formData, handleFieldChange }}
              />
            )}
          </>
        ) : (
          <>
            <header>
              <h1 className={s.heading}>Password recovery</h1>
            </header>

            <FormForgotPassword />
          </>
        )}
      </div>

      <BaseGenericModal
        className={s.modal}
        modalId={EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS}
        size={EModalSize.XL650}>
        <BaseClose
          className={s.closeBtn}
          onClick={() => {
            dispatch(closeModal(EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS));
          }}
        />

        <div className={s.termsWrapper}>
          <header className={s.termsHeader}>
            {/* <BaseIcon className={s.gsLogo} icon="gs-logo-white" size={74} /> */}
            <BaseIcon className={s.gsLogo} icon="landing-logo-dark" size={74} />
            <h1 className={s.heading2}>Terms of Service</h1>
          </header>

          <CustomScrollbar className={s.termsContent}>
            <main dangerouslySetInnerHTML={{ __html: termsAndConditions }}></main>
          </CustomScrollbar>

          <footer className={s.termsFooter}>
            <BaseButton
              className={s.termsBtn}
              theme="grey3"
              onClick={() => {
                handleFieldChange({ name: 'termsAccepted', value: false });
                dispatch(closeModal(EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS));
              }}>
              Decline
            </BaseButton>
            <BaseButton
              className={s.termsBtn}
              onClick={() => {
                handleFieldChange({ name: 'termsAccepted', value: true });
                dispatch(closeModal(EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS));
              }}>
              I accept the Terms of Service
            </BaseButton>
          </footer>
        </div>
      </BaseGenericModal>
    </>
  );
};
