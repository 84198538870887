import React, { useCallback } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { EResultsTab } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';
import { Tabs } from 'src/components/Tabs';
import { TCustomResultHeaderProps } from 'src/features/Results/DashboardHeader/CustomResultHeader/CustomResultHeader.types';
import { HeaderSimulationFlag } from 'src/features/Results/DashboardHeader/CustomResultHeader/HeaderSimulationFlag';
import { SelectDay } from 'src/features/Results/DashboardHeader/CustomResultHeader/SelectDay';
import { SelectMonth } from 'src/features/Results/DashboardHeader/CustomResultHeader/SelectMonth';
import { DataAggregationResolution } from 'src/graphql';
import { useAuth } from 'src/hooks/useAuth';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useResults } from 'src/hooks/useResults';
import {
  selectActiveResultsTab,
  selectSelectedDataResolution,
} from 'src/redux/application/application.selectors';
import {
  setActiveResultsTab,
  setSelectedDataResolution,
  setSelectedEndDatetime,
  setSelectedMemberUuid,
  setSelectedStartDatetime,
} from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { UTCMoment } from 'src/utils/UTCMoment';

import s from './CustomResultHeader.module.scss';

export const CustomResultHeader: React.FC<TCustomResultHeaderProps> = ({
  onClickGoBack,
  flag = 'Operation',
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isMember, isAdmin, userName } = useAuth();
  const { maxDate, startOfMaxDate, endOfMaxDate } = useResults();
  const { selectedMember, communityMembers } = useConfiguration();

  const selectedDataResolution = useSelector(selectSelectedDataResolution);
  const activeResultsTab = useSelector(selectActiveResultsTab);

  const handleResolutionButton = useCallback<(value: DataAggregationResolution) => void>(
    (value) => {
      batch(() => {
        if (value === DataAggregationResolution.Daily) {
          dispatch(setSelectedStartDatetime(startOfMaxDate));
          dispatch(setSelectedEndDatetime(endOfMaxDate));
        } else if (value === DataAggregationResolution.Monthly) {
          const date = UTCMoment.utc(maxDate);
          const startOfMonth = date.startOf('month').toDate().toISOString();
          const endOfMonth = date.endOf('month').toDate().toISOString();
          dispatch(setSelectedStartDatetime(startOfMonth));
          dispatch(setSelectedEndDatetime(endOfMonth));
        }

        dispatch(setSelectedDataResolution(value));
      });
    },
    [dispatch, maxDate, startOfMaxDate, endOfMaxDate],
  );

  const handleClickTab = (tab: EResultsTab) => {
    dispatch(setActiveResultsTab(tab));

    if (tab === EResultsTab.Community) {
      dispatch(setSelectedMemberUuid(null));
    } else {
      const communityMember = communityMembers.find((member) => member?.email === userName);
      if (!!communityMember?.uuid) dispatch(setSelectedMemberUuid(communityMember.uuid));
    }
  };

  return (
    <div className={s.customResultHeaderWrapper}>
      {!!selectedMember && isAdmin && (
        <div className={s.homeName} onClick={onClickGoBack}>
          <div className={s.iconContainer}>
            <div className={s.borderIcon} />
            <BaseIcon icon="arrow-left-full" size={15} />
          </div>
          <span>{selectedMember.name}</span>
        </div>
      )}
      {!!selectedMember && isMember && (
        <Tabs
          tabs={[EResultsTab.Home, EResultsTab.Community]}
          activeTab={activeResultsTab}
          handleClick={handleClickTab}
        />
      )}
      {!isMember && !selectedMember && (
        <div className={s.communityName}>
          <span>{t('COMMUNITY_RESULTS')}</span>
        </div>
      )}
      <div className={s.topRightArea}>
        {selectedDataResolution === DataAggregationResolution.Monthly && <SelectMonth />}
        {selectedDataResolution === DataAggregationResolution.Daily && <SelectDay />}
        <div className={s.dailyMonthlyContainer}>
          <button
            type="button"
            className={classNames(s.text, {
              [s.selected]: selectedDataResolution === DataAggregationResolution.Monthly,
            })}
            onClick={() => handleResolutionButton(DataAggregationResolution.Monthly)}>
            {t('MONTHLY')}
          </button>
          <button
            type="button"
            className={classNames(s.text, {
              [s.selected]: selectedDataResolution === DataAggregationResolution.Daily,
            })}
            onClick={() => handleResolutionButton(DataAggregationResolution.Daily)}>
            {t('DAILY')}
          </button>
        </div>
        {flag === 'Operation' && !isMember && (
          <div className={s.liveResultsContainer}>
            <span className={s.text}>{t('OPERATION_RESULTS')}</span>
          </div>
        )}
        {flag === 'Simulation' && (
          <HeaderSimulationFlag
            className={s.simulationResults}
            isIconActive={true}
            title={t('SIMULATION_RESULTS')}
          />
        )}
      </div>
    </div>
  );
};
