import React from 'react';

import classNames from 'classnames';
import { BaseIcon } from 'src/components/BaseIcon';
import s from 'src/components/ModalAssetsManager/ModalAssetsManager.module.scss';
import { TLiteSummaryPopupProps } from 'src/features/Results/SingleCommunityResults/SingleCommunityResults.types';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { get2dAssetIcon } from 'src/utils/assetIcons';
import { formatter } from 'src/utils/formatter';
import { flattenChildren } from 'src/utils/general';

export const LiteSummaryPopup: React.FC<TLiteSummaryPopupProps> = ({ area, name, icon }) => {
  const { assetTypes } = useConfiguration();

  return (
    <div className={classNames(s.modalTooltip)}>
      <div className={classNames(s.modalInner, 'gradient-dark')}>
        <div className={s.paddingAround}>
          <header className={s.header}>
            <div className={s.typeIcon}>
              <BaseIcon icon={icon} size={12} />
            </div>

            <h3 className={s.title}>{name}</h3>
          </header>

          <ul className={s.assetsList}>
            {assetTypes(flattenChildren(area?.children)).map((asset, index) => (
              <li key={index}>
                <button
                  type="button"
                  className={s.assetSquareBtn}
                  onClick={() => void 0}
                  disabled={false}>
                  <BaseIcon icon={get2dAssetIcon(asset.type)} size={16.25} />
                  <label className={s.assetsAmount}>{formatter.prefixWithZeros(asset.count)}</label>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={s.triangle} />
    </div>
  );
};
