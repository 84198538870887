import React, { useEffect, useMemo, useRef } from 'react';

import { useSelector } from 'react-redux';
import { LoggedComponent } from 'src/components/Header/LoggedComponent/LoggedComponent';
import { LoggedMobileComponent } from 'src/components/Header/LoggedMobileComponent';
import { NotLoggedComponent } from 'src/components/Header/NotLoggedComponent/NotLoggedComponent';
import { EActiveTabState } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';
import { EPredefinedModalIds } from 'src/constants/modals';
import { useMoveCollaborationToCanaryNetworkMutation } from 'src/graphql';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useAuth } from 'src/hooks/useAuth';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useMapNavigation } from 'src/hooks/useMapNavigation';
import { useModal } from 'src/hooks/useModal';
import { selectScreenMode } from 'src/redux/application/application.selectors';
import { setActiveSidebarMainTab } from 'src/redux/application/application.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationUuid,
  selectSimulationResults,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal, toggleModal } from 'src/redux/modals/modals.slice';
import {
  selectGlobalNotificationsList,
  selectMeasurementNotificationList,
} from 'src/redux/notifications/notifications.selectors';
import {
  selectIsOperationalCommunity,
  selectPreventPushResultsScreen,
} from 'src/redux/scm/scm.selectors';
import { setActiveSCMStep } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesTitles } from 'src/routes/routes.config';
import { EScreenMode } from 'src/typings/configuration.types';
import { convertUserToAdminRequestOnNotification } from 'src/utils/notification';

import { THeaderProps } from './Header.types';

export enum EHeaderMode {
  Default = 'DEFAULT',
  Community = 'COMMUNITY',
}

export const Header: React.FC<THeaderProps> = ({}) => {
  const location = useAppLocation();
  const dispatch = useAppDispatch();
  const simulationStatus = useSelector(selectSimulationStatus);
  const simulationResults = useSelector(selectSimulationResults);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const { discardCurrentConfiguration } = useMapNavigation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const configUuid = useSelector(selectActiveConfigurationUuid);

  const { configuration } = useConfiguration();

  const configurationName: string = useMemo(() => configuration?.name || '', [configuration]);

  const title =
    !!configUuid && configUuid !== 'NEW_COMMUNITY'
      ? configurationName
      : routesTitles[location.routeName];

  const { id: burgerMenuModalId } = useModal();
  const { id: notificationsModalUuid } = useModal();
  const isBurgerMenuOpened = useSelector(selectIsModalOpened(burgerMenuModalId));
  const notificationsList = useSelector(selectGlobalNotificationsList);
  const { isMember } = useAuth();
  const measurementNotifications = useSelector(selectMeasurementNotificationList);
  const preventPushResultsScreen = useSelector(selectPreventPushResultsScreen);
  const screenMode = useSelector(selectScreenMode);
  const simulationStatusRef = useRef(simulationStatus);

  useEffect(() => {
    const subStatement = isOperationalCommunity
      ? simulationResults
      : simulationStatus === 'finished' &&
        simulationResults &&
        (!preventPushResultsScreen ||
          (simulationStatusRef.current === 'running' && simulationStatus === 'finished'));

    // handles notifications for SCM, when the simulataion finishes running
    if (subStatement && !preventPushResultsScreen) {
      dispatch(toggleModal(notificationsModalUuid));
      dispatch(setActiveSCMStep(3));
      dispatch(setActiveSidebarMainTab(EActiveTabState.Community));
    }
    if (isMember) {
      dispatch(toggleModal(notificationsModalUuid));
    }
    if (simulationStatusRef.current !== simulationStatus) {
      simulationStatusRef.current = simulationStatus;
    }
  }, [
    dispatch,
    simulationStatus,
    notificationsModalUuid,
    isMember,
    simulationResults,
    isOperationalCommunity,
    preventPushResultsScreen,
  ]);

  useEffect(() => {
    if (isOperationalCommunity && measurementNotifications.length > 0) {
      dispatch(toggleModal(notificationsModalUuid));
    }
  }, [dispatch, isOperationalCommunity, measurementNotifications, notificationsModalUuid]);

  const {
    loading: moveCollaborationToCanaryNetworkLoading,
  } = useMoveCollaborationToCanaryNetworkMutation()[1];

  // show the ModalOperateProcess modal when moveCollaborationToCanaryNetworkLoading is true
  useEffect(() => {
    if (moveCollaborationToCanaryNetworkLoading) {
      dispatch(openModal(EPredefinedModalIds.MODAL_OPERATE_PROCESS));
    } else {
      dispatch(closeModal(EPredefinedModalIds.MODAL_OPERATE_PROCESS));
    }
  }, [dispatch, moveCollaborationToCanaryNetworkLoading]);

  const memoizedNotifications = useMemo(() => {
    if (notificationsList.length !== 0) {
      return notificationsList.map(
        // @ts-ignore
        (item) => item && convertUserToAdminRequestOnNotification(item),
      );
    }
  }, [notificationsList]);

  function handleHamburgerClick() {
    if (isBurgerMenuOpened) {
      dispatch(closeModal(burgerMenuModalId));
    } else {
      dispatch(openModal(burgerMenuModalId));
    }
  }

  const handleLogoRedirection = () => {
    if (discardCurrentConfiguration) discardCurrentConfiguration({ zoomOut: true });
  };

  if (isLoggedIn) {
    return screenMode === EScreenMode.Mobile ? (
      <LoggedMobileComponent
        title={title}
        handleHamburgerClick={handleHamburgerClick}
        handleLogoRedirection={handleLogoRedirection}
        memoizedNotifications={memoizedNotifications}
      />
    ) : (
      <LoggedComponent />
    );
  } else {
    return <NotLoggedComponent />;
  }
};
