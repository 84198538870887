import React, { useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { uploadedFilesCache } from 'src/cache/uploadedFiles';
import { BaseButton } from 'src/components/BaseButton';
import { TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import { InputFieldWithLabel } from 'src/components/InputField';
import mapSidebarStyles from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/MapSidebarSingleCommunity.module.scss';
import {
  ConfigurationFieldsFragment,
  ConfigurationOutput,
  ReadConfigurationDocument,
  useListCollaborationsQuery,
  useUpdateConfigurationMutation,
} from 'src/graphql';
import { useConfiguration } from 'src/hooks/useConfiguration';
import {
  selectActiveConfigurationUuid,
  selectReadOnly,
} from 'src/redux/configuration/configuration.selectors';
import { TFieldValue, TFileIdentifiers } from 'src/typings/base-types';
import { TSettingsData } from 'src/utils/assetsFields/assetsFields.types';
import { validateFields } from 'src/utils/fieldValidation';
import { areObjectsEqualByFields } from 'src/utils/general';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

import s from './BasicSettingsForm.module.scss';
import { TBasicSettingsFormProps } from './BasicSettingsForm.types';
import { validators, basicSettingsFields } from './fields';

type THandleChangeArgs = {
  name: keyof TSettingsData | 'locationVisible' | 'name';
  value: TFieldValue;
};

export const BasicSettingsForm: React.FC<TBasicSettingsFormProps> = ({
  onSubmit,
  id,
  className,
}) => {
  const { t } = useTranslation();

  const configUuid = useSelector(selectActiveConfigurationUuid);
  const readOnly = useSelector(selectReadOnly);
  const containerRef = useRef<HTMLFormElement>(null);

  const { configuration, isSimulationCommunity } = useConfiguration();

  const [newBasicSettings, setNewBasicSettings] = useState<
    (ConfigurationOutput & { logo?: TFileIdentifiers }) | null
  >(configuration);
  const [originalBasicSettings, setOriginalBasicSettings] = useState<ConfigurationOutput | null>(
    configuration,
  );
  const [errors, setErrors] = useState<TFormFieldsGeneratorProps['errors']>(null);

  const { data: listCollaborationsResponse } = useListCollaborationsQuery({
    fetchPolicy: 'cache-first',
  });
  const [updateConfiguration] = useUpdateConfigurationMutation({
    errorPolicy: 'none',
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUuid } }],
    onCompleted: (data) => {
      batch(() => {
        dispatchSuccessToast();
        if (!!data?.updateConfiguration) {
          setOriginalBasicSettings(data.updateConfiguration as ConfigurationOutput);
        }
      });
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });
  const projectNames = useMemo(() => {
    const projects = listCollaborationsResponse?.listCollaborations?.configurations || [];
    return projects
      .map((item) => item?.name)
      .filter((item): item is string => typeof item === 'string');
  }, [listCollaborationsResponse]);

  const handleChange = ({ name, value }) => {
    if (name === 'startEndDate') {
      return setNewBasicSettings({
        ...newBasicSettings,
        settingsData: {
          ...newBasicSettings?.settingsData,
          startDate: value.startDate,
          endDate: value.endDate,
        },
      });
    }

    if (name === 'currency') {
      return setNewBasicSettings({
        ...newBasicSettings,
        settingsData: {
          ...newBasicSettings?.settingsData,
          currency: value,
        },
      });
    }

    if (name === 'logo') {
      return setNewBasicSettings({
        ...newBasicSettings,
        settingsData: {
          ...newBasicSettings?.settingsData,
          logo: value.name,
        },
        logo: value,
      });
    }

    if (name === 'companyName') {
      return setNewBasicSettings({
        ...newBasicSettings,
        communityAdvancedSettings: {
          ...newBasicSettings?.communityAdvancedSettings,
          companyName: value,
        },
      });
    }

    setNewBasicSettings({
      ...newBasicSettings,
      [name]: value,
    });

    const output = validateFields({
      validators: validators({
        usedProjectNames: projectNames,
        currentProjectName: newBasicSettings?.name || '',
      }),
    });
    setErrors(output.errors);
  };

  const fields = useMemo(
    () => basicSettingsFields({ values: { uuid: configUuid, ...newBasicSettings } }),
    [newBasicSettings, configUuid],
  );

  const handleSubmit = async () => {
    const output = validateFields({
      validators: validators({
        usedProjectNames: projectNames,
        currentProjectName: newBasicSettings?.name || '',
      }),
    });
    setErrors(output.errors);

    const defaultValues = fields.reduce((acc, field) => {
      acc[field.name] = field.defaultValue;
      return acc;
    }, {});
    const defaultSettingsData = fields
      .filter((field) => field.name === 'startEndDate')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((acc, field: any) => {
        acc['startDate'] = field?.defaultValue?.startDate;
        acc['endDate'] = field.defaultValue.endDate;
        return acc;
      }, {});
    const community: ConfigurationOutput & { logo?: TFileIdentifiers } = {
      ...defaultValues,
      ...newBasicSettings,
      settingsData: {
        ...defaultSettingsData,
        ...newBasicSettings?.settingsData,
      },
      communityAdvancedSettings: {
        ...newBasicSettings?.communityAdvancedSettings,
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (onSubmit) return onSubmit((community as ConfigurationFieldsFragment) as any);

    if (
      !!configUuid &&
      !(configUuid === 'NEW_COMMUNITY') &&
      !!configuration?.project?.uuid &&
      !!community?.settingsData &&
      !!community?.communityAdvancedSettings
    ) {
      updateConfiguration({
        variables: {
          name: community.name,
          description: community.description,
          timezone: community.timezone,
          settingsData: {
            logo: !!community.logo ? uploadedFilesCache.get(community.logo) : null,
            startDate: community.settingsData.startDate,
            endDate: community.settingsData.endDate,
            currency: community.settingsData.currency,
            communityAdvancedSettings: {
              companyName: community.communityAdvancedSettings.companyName,
            },
          },
          configurationUuid: configUuid,
          projectUuid: configuration?.project?.uuid,
        },
      });
    }
  };

  const canSave = !areObjectsEqualByFields(newBasicSettings, originalBasicSettings, undefined, [
    'timestamp',
    'warnings',
  ]);

  return (
    <form
      className={classnames(s.container, className)}
      onSubmit={(e) => {
        e.preventDefault();
        if (readOnly) return;

        handleSubmit();
      }}
      id={id}
      ref={containerRef}>
      {/* Name */}
      {fields
        .filter((field) => field.name === 'name')
        .map((field) => (
          <InputFieldWithLabel
            key={field.name}
            type={field.type}
            name={field.name}
            label={t(field.label!)}
            value={field.value !== undefined ? field.value : field.defaultValue}
            onChange={(val) => handleChange(val as THandleChangeArgs)}
            autoComplete="off"
            error={errors?.[field.name]}
            theme="filled-gray"
          />
        ))}
      {/* Logo */}
      {fields
        .filter((field) => field.name === 'logo' && !field.exclude)
        .map((field) => (
          <InputFieldWithLabel
            key={field.name}
            type={field.type}
            name={field.name}
            label={t(field.label!)}
            value={!!field?.value ? field.value : field.defaultValue}
            onChange={(val) =>
              handleChange(({ name: 'logo', value: val.value } as unknown) as THandleChangeArgs)
            }
            allowDownload={true}
            accept="image/*"
            error={errors?.[field.name]}
            theme="filled-gray"
          />
        ))}
      {/* Company Name */}
      {fields
        .filter((field) => field.name === 'companyName' && !field.exclude)
        .map((field) => (
          <InputFieldWithLabel
            key={field.name}
            type={field.type}
            name={field.name}
            label={t(field.label!)}
            value={field.value || field.defaultValue}
            onChange={(val) => handleChange(val as THandleChangeArgs)}
            error={errors?.[field.name]}
            theme="filled-gray"
          />
        ))}
      {/* Description */}
      {fields
        .filter((field) => field.name === 'description')
        .map((field) => (
          <InputFieldWithLabel
            key={field.name}
            type={field.type}
            name={field.name}
            label={t(field.label!)}
            value={field.value || field.defaultValue}
            onChange={(val) => handleChange(val as THandleChangeArgs)}
            inputHeight="10"
            error={errors?.[field.name]}
            theme="filled-gray"
          />
        ))}
      {/* Simulation Length */}
      {isSimulationCommunity ||
        (configUuid === 'NEW_COMMUNITY' &&
          fields
            .filter((field) => field.name === 'startEndDate')
            .map((field) => (
              <InputFieldWithLabel
                key={field.name}
                type={field.type}
                name={field.name}
                label={t(field.label!)}
                startValue={
                  newBasicSettings?.settingsData?.startDate ||
                  UTCMoment.utc(new Date()).format(BACKEND_DATE_FORMATS.SETTINGS_DATA)
                }
                endValue={
                  newBasicSettings?.settingsData?.endDate ||
                  UTCMoment.utc(new Date())
                    .add(7, 'days')
                    .format(BACKEND_DATE_FORMATS.SETTINGS_DATA)
                }
                valueFormat={BACKEND_DATE_FORMATS.SETTINGS_DATA}
                minDate={UTCMoment.utc(newBasicSettings?.settingsData?.startDate)
                  .add(1, 'day')
                  .toDate()}
                maxDate={UTCMoment.utc(newBasicSettings?.settingsData?.endDate)
                  .subtract(1, 'day')
                  .toDate()}
                onChange={({ startDate, endDate }) => {
                  handleChange({
                    name: 'startEndDate',
                    value: { startDate, endDate },
                  });
                }}
                tags={[
                  {
                    value: 7,
                    label: `1 ${t('common.WEEK')}`,
                    badge: `${t('common.RECOMMENDED')}`,
                  },
                  {
                    value: 30,
                    label: `1 ${t('common.MONTH')}`,
                  },
                ]}
                error={errors?.[field.name]}
                theme="light"
              />
            )))}
      {/* Currency */}
      {fields
        .filter((field) => field.name === 'currency')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((field: any) => (
          <InputFieldWithLabel
            key={field.name}
            type={field.type}
            name={field.name}
            label={t(field.label!)}
            value={field.value || field.defaultValue}
            options={field.options}
            onChange={(val) => handleChange(val as THandleChangeArgs)}
            error={errors?.[field.name]}
            theme="filled-gray"
          />
        ))}
      {/* Community Timezone */}
      {fields
        .filter((field) => field.name === 'timezone')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((field: any) => (
          <InputFieldWithLabel
            key={field.name}
            type={field.type}
            name={field.name}
            label={t(field.label!)}
            value={field.value || field.defaultValue}
            options={field.options}
            onChange={(val) => handleChange(val as THandleChangeArgs)}
            error={errors?.[field.name]}
            theme="filled-gray"
          />
        ))}
      {/* Language */}
      {fields
        .filter((field) => field.name === 'language')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((field: any) => (
          <InputFieldWithLabel
            key={field.name}
            type={field.type}
            name={field.name}
            label={t(field.label!)}
            value={field.value || field.defaultValue}
            options={field.options}
            onChange={(val) => handleChange(val as THandleChangeArgs)}
            error={errors?.[field.name]}
            theme="filled-gray"
          />
        ))}
      <div className={mapSidebarStyles.formButtonsWrapper}>
        <BaseButton
          type="submit"
          className={mapSidebarStyles.formButton}
          form={id}
          disabled={readOnly || !canSave}>
          {t('commands.SAVE')}
        </BaseButton>
      </div>
    </form>
  );
};
