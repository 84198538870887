import { ChartOptions, ChartType } from 'chart.js';
import {
  commonChartOptions,
  commonInteraction,
  commonTooltipSettings,
  commonXScale,
} from 'src/components/Charts/chartsData/commonOptions';
import {
  DeviceProfileFourConfig,
  DeviceProfileOneConfig,
  DeviceProfileTankConfig,
  DeviceProfileThreeConfig,
  DeviceProfileTwoConfig,
} from 'src/components/Charts/chartsData/deviceProfile.config';
import { SavingsConfig } from 'src/components/Charts/chartsData/savings.config';
import { tooltipCallbacks } from 'src/components/Charts/chartsData/tooltipCallbacks';
import { formatter } from 'src/utils/formatter';

export enum EChartName {
  Prices = 'PRICES',
  DayProfile = 'DAY_PROFILE',
  Self = 'SELF',
  HouseSummary = 'HOUSE_SUMMARY',
  SimulationProgress = 'SIMULATION_PROGRESS',
  EnergyTradeProfile = 'ENERGY_TRADE_PROFILE',
  DeviceProfileOne = 'DEVICE_PROFILE_ONE',
  DeviceProfileTwo = 'DEVICE_PROFILE_TWO_FOUR',
  DeviceProfileThree = 'DEVICE_PROFILE_THREE',
  DeviceProfileFour = 'DEVICE_PROFILE_FOUR',
  Savings = 'SAVINGS',
  EnergyBill = 'ENERGY_BILL',
  DeviceProfileTank = 'DEVICE_PROFILE_TANK',
}

export const chartTypes: { [key in EChartName]: ChartType } = {
  [EChartName.Prices]: 'line',
  [EChartName.DayProfile]: 'line',
  [EChartName.Self]: 'doughnut',
  [EChartName.HouseSummary]: 'line',
  [EChartName.SimulationProgress]: 'line',
  [EChartName.EnergyTradeProfile]: 'bar',
  [EChartName.DeviceProfileOne]: 'line',
  [EChartName.DeviceProfileTwo]: 'bar',
  [EChartName.DeviceProfileThree]: 'bar',
  [EChartName.DeviceProfileFour]: 'bar',
  [EChartName.DeviceProfileTank]: 'line',
  [EChartName.Savings]: 'bar',
  [EChartName.EnergyBill]: 'doughnut',
};

export function getChartType(chartName: EChartName): ChartType {
  return chartTypes[chartName];
}

export function getChartOptions(chartName: EChartName): ChartOptions {
  switch (chartName) {
    case EChartName.DayProfile:
      return {
        ...commonChartOptions,
        scales: {
          x: commonXScale,
          y: {
            ticks: {
              padding: 0,
              display: false,
            },
            grid: {
              drawBorder: false,
              borderDash: [2, 5],
              drawTicks: false,
            },
          },
          // yVolumeTrades: {
          //   ...dayProfileCommonYAxis,
          //   grid: {
          //     drawBorder: false,
          //     borderDash: [2, 5],
          //     drawTicks: false,
          //   },
          // },
          // yPricesAndFees: dayProfileCommonYAxis,
        },
        interaction: commonInteraction,
        plugins: {
          tooltip: {
            ...commonTooltipSettings,
            callbacks: {
              title: tooltipCallbacks.title,
              label: tooltipCallbacks.label,
            },
          },
        },
      } as ChartOptions;
    case EChartName.Prices:
      return {
        ...commonChartOptions,
        scales: {
          x: commonXScale,
          y: {
            ticks: {
              padding: 10,
              display: false,
            },
            grid: {
              drawBorder: false,
              borderDash: [2, 5],
              drawTicks: false,
            },
          },
        },
        interaction: commonInteraction,

        plugins: {
          tooltip: {
            ...commonTooltipSettings,
            callbacks: {
              title: tooltipCallbacks.title,
              label: tooltipCallbacks.label,
            },
          },
        },
      } as ChartOptions;
    case EChartName.Self:
      return {
        layout: {
          padding: 0,
        },
        cutout: '80%',
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      } as ChartOptions;
    case EChartName.EnergyBill:
      return {
        layout: {
          padding: 10,
        },
        borderWidth: 2,
        borderColor: '#fff',
        cutout: '80%',
        plugins: {
          tooltip: {
            enabled: true,
          },
        },
      } as ChartOptions;
    case EChartName.HouseSummary:
      return {
        ...commonChartOptions,
        scales: {
          x: {
            display: false,
            type: 'time',
          },
          y: {
            display: false,
          },
          'y-net-energy': {
            display: false,
          },
          'y-prices': {
            display: false,
          },
        },
        interaction: commonInteraction,

        plugins: {
          tooltip: {
            enabled: false,
          },
        },
      } as ChartOptions;
    case EChartName.SimulationProgress:
      return {
        ...commonChartOptions,
        responsive: false,
        scales: {
          x: {
            type: 'time',
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
          },
          y: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 1,
          },
        },
        plugins: {
          decimation: {
            enabled: true,
            // @ts-ignore
            algorithm: 'lttb',
            samples: 200,
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
      } as ChartOptions;
    case EChartName.EnergyTradeProfile:
      return {
        animation: false,
        responsive: true,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            stacked: true,
            type: 'time',
            ticks: {
              display: false,
            },
            // time: {
            //   unit: 'day', // This changes how data is plotted, that's why we use AxisXCustom
            // },
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            ticks: {
              display: false,
            },
            grid: {
              drawTicks: false,
              drawBorder: false,
              borderDash: [2, 5],
            },
          },
        },
        interaction: commonInteraction,
        plugins: {
          tooltip: {
            ...commonTooltipSettings,
            callbacks: {
              title: tooltipCallbacks.title,
              label: tooltipCallbacks.label,
            },
          },
        },
      };
    case EChartName.DeviceProfileTank:
      return DeviceProfileTankConfig;

    case EChartName.DeviceProfileOne:
      return DeviceProfileOneConfig;

    case EChartName.DeviceProfileTwo:
      return DeviceProfileTwoConfig;

    case EChartName.DeviceProfileThree:
      return DeviceProfileThreeConfig;

    case EChartName.DeviceProfileFour:
      return DeviceProfileFourConfig;

    case EChartName.Savings:
      return SavingsConfig;
  }
}
