import React, { useRef, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseErrorMessage } from 'src/components/BaseErrorMessage';
import { BaseInfoMessage } from 'src/components/BaseInfoMessage';
import { BaseSelect } from 'src/components/BaseSelect';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { InfoHelper } from 'src/components/InfoHelper';
import {
  ScmAdvancedSettingsOutput,
  ScmCoefficientAlgorithm,
  ScmCommunityMember,
  ScmSelfConsumptionTypeEnum,
  useUpdateConfigurationMutation,
} from 'src/graphql';
import { useUpdateMemberCoefficientsMutation } from 'src/graphql';
import { ReadConfigurationDocument } from 'src/graphql';
import { ConfigurationOutput } from 'src/graphql';
import { useReadConfigurationQuery } from 'src/graphql';
import { selectReadOnly } from 'src/redux/configuration/configuration.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { areObjectsEqualByFields, arrayToObject, sumOfField } from 'src/utils/general';
import { areArraysOfObjectsEqualByFields } from 'src/utils/general';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';

import {
  SHARING_COEFFICIENT_OPTIONS,
  SELF_CONSUMPTION_TYPE_OPTIONS,
} from './AdvancedSettingsForm.constants';
import s from './AdvancedSettingsForm.module.scss';
import { CoefficientAssetTile } from './CoefficientAssetTile';

export const AdvancedSettingsForm: React.FC = () => {
  const { t } = useTranslation();

  const configUuid = useSelector(selectActiveConfigurationUuid);
  const readOnly = useSelector(selectReadOnly);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !configUuid,
    variables: { uuid: configUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const communityMembers: ScmCommunityMember[] = useMemo(() => {
    const members = (configuration?.scenarioData?.homeInfo?.scmHomeDetails ?? []).filter(
      (member): member is ScmCommunityMember => member !== null,
    );
    return members;
  }, [configuration]);
  const advancedSettings: ScmAdvancedSettingsOutput = useMemo(
    () =>
      configuration?.communityAdvancedSettings || {
        selfConsumptionType: ScmSelfConsumptionTypeEnum.CollectiveSelfConsumptionSurplus_42,
        coefficientAlgorithm: ScmCoefficientAlgorithm.Static,
      },
    [configuration],
  );

  const [newCommunityMembers, setNewCommunityMembers] = useState<ScmCommunityMember[]>(
    communityMembers,
  );
  const [originalCommunityMembers, setOriginalCommunityMembers] = useState<ScmCommunityMember[]>(
    communityMembers,
  );
  const [newAdvancedSettings, setNewAdvancedSettings] = useState<ScmAdvancedSettingsOutput>(
    advancedSettings,
  );
  const [
    originalAdvancedSettings,
    setOriginalAdvancedSettings,
  ] = useState<ScmAdvancedSettingsOutput>(advancedSettings);

  const [updateMemberCoefficients] = useUpdateMemberCoefficientsMutation({
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUuid } }],
    errorPolicy: 'all',
    onCompleted: () => {
      setOriginalCommunityMembers(newCommunityMembers);
      dispatchSuccessToast();
    },
    onError: (err) => dispatchErrorToast(err),
  });

  const [updateConfiguration, { loading }] = useUpdateConfigurationMutation({
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUuid } }],
    errorPolicy: 'none',
    onCompleted: () => {
      dispatchSuccessToast();
      setOriginalAdvancedSettings(newAdvancedSettings);
    },
    onError: (err) => dispatchErrorToast(err),
  });

  const containerRef = useRef<HTMLFormElement>(null);

  const onValueChange = (uuid, value) => {
    if (typeof value === 'number') {
      const updatedCommunityMembers = newCommunityMembers.map((item) =>
        item.uuid === uuid ? { ...item, coefficientPercentage: value } : item,
      );
      setNewCommunityMembers(updatedCommunityMembers);
    }
  };

  const handleSelectChange = ({ name, value }) => {
    setNewAdvancedSettings({ ...newAdvancedSettings, [name]: value });
  };

  const isValidForm = sumOfField(newCommunityMembers, 'coefficientPercentage') === 1;
  const canSaveCommunityMembers =
    !areArraysOfObjectsEqualByFields(newCommunityMembers, originalCommunityMembers, [
      'coefficientPercentage',
    ]) && isValidForm;
  const canSaveAdvancedSettings = !areObjectsEqualByFields(
    newAdvancedSettings,
    originalAdvancedSettings,
    ['coefficientAlgorithm', 'selfConsumptionType'],
  );

  const canSave = useMemo(() => (canSaveCommunityMembers || canSaveAdvancedSettings) && !loading, [
    canSaveCommunityMembers,
    canSaveAdvancedSettings,
    loading,
  ]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (readOnly) return;
    if (configUuid && configuration?.project?.uuid) {
      if (canSaveCommunityMembers) {
        const memberCoefficientMapping = arrayToObject(
          newCommunityMembers,
          'uuid',
          'coefficientPercentage',
        );
        updateMemberCoefficients({
          variables: {
            configUuid: configUuid,
            memberCoefficientMapping: JSON.stringify(memberCoefficientMapping),
          },
        });
      }

      if (canSaveAdvancedSettings) {
        updateConfiguration({
          variables: {
            configurationUuid: configUuid,
            projectUuid: configuration?.project?.uuid,
            settingsData: {
              communityAdvancedSettings: {
                selfConsumptionType: newAdvancedSettings.selfConsumptionType,
                coefficientAlgorithm: newAdvancedSettings.coefficientAlgorithm,
              },
            },
          },
        });
      }
    }
  };

  const disabledCoefficient: boolean = useMemo<boolean>(
    () =>
      readOnly ||
      isOperationalCommunity ||
      newAdvancedSettings.coefficientAlgorithm === ScmCoefficientAlgorithm.Dynamic,

    [readOnly, isOperationalCommunity, newAdvancedSettings.coefficientAlgorithm],
  );

  return (
    <div className={s.container}>
      <form onSubmit={onSubmit} id={'sharingcoefficient'} ref={containerRef}>
        <div className={s.headingText}>
          {t('labels.ENERGY_SHARING_MECHANISM')}
          <InfoHelper info={t('tooltips.ENERGY_SHARING_MECHANISM_INFO')} />
        </div>
        <FieldContainer showTooltip={false} tooltipText="" className={s.selectField}>
          <BaseSelect
            onChange={(option) =>
              handleSelectChange({ name: 'coefficientAlgorithm', value: option.value })
            }
            name="scmCoefficientAlgorithm"
            value={newAdvancedSettings.coefficientAlgorithm}
            options={SHARING_COEFFICIENT_OPTIONS}
            theme="filled-gray"
            showTooltip
            disabled={readOnly}
            tooltipText=""
          />
          {!isValidForm && <BaseErrorMessage>{t('messages.COEFFICIENTS_SUM_1')}</BaseErrorMessage>}
          {newAdvancedSettings.coefficientAlgorithm === ScmCoefficientAlgorithm.Dynamic && (
            <BaseInfoMessage>{t('messages.DYNAMIC_COEFFICIENTS')}</BaseInfoMessage>
          )}
        </FieldContainer>

        <div className={s.headingText}>{t('labels.SET_COEFFICIENT')}</div>
        {communityMembers.length &&
          communityMembers.map((communityMember) => (
            <div key={communityMember.uuid}>
              <CoefficientAssetTile
                data={communityMember}
                onAssetValueChange={(uuid, value) => onValueChange(uuid, value)}
                disabled={disabledCoefficient}
              />
            </div>
          ))}

        <div className={s.headingText}>{t('labels.SELF_CONSUMPTION_TYPE')}</div>
        <FieldContainer showTooltip={false} tooltipText="" className={s.selectField}>
          <BaseSelect
            name="selfConsumptionType"
            options={SELF_CONSUMPTION_TYPE_OPTIONS}
            onChange={(option) =>
              handleSelectChange({ name: 'selfConsumptionType', value: option.value })
            }
            value={newAdvancedSettings.selfConsumptionType}
            theme="filled-gray"
            showTooltip
          />
        </FieldContainer>

        <div className={s.formButtonsWrapper}>
          <BaseButton
            type="submit"
            className={s.formButton}
            form="sharingcoefficient"
            disabled={!canSave || readOnly}>
            {t('commands.SAVE')}
          </BaseButton>
        </div>
      </form>
    </div>
  );
};
