import React from 'react';

import classnames from 'classnames';
import { BaseIcon } from 'src/components/BaseIcon';
import { InViewPositioner } from 'src/components/InViewPositioner';
import s from 'src/components/ModalAssetsManager/ModalAssetsManager.module.scss';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { get2dAssetIcon } from 'src/utils/assetIcons';
import { formatter } from 'src/utils/formatter';
import { flattenChildren } from 'src/utils/general';

export const ModalCommunitySummary: React.FC = () => {
  const { configuration, communityArea, assetTypes } = useConfiguration();

  return (
    <InViewPositioner
      className={classnames(s.modal, s.modalTooltip)}
      innerClassName={classnames(s.modalInner, 'gradient-dark', s.pointerEvents)}
      recalculateKey={false}
      reservedHeight={0}>
      <div className={s.paddingAround}>
        <header className={s.header}>
          <div className={s.typeIcon}>
            <BaseIcon icon="market" size={12} />
          </div>

          <h3 className={s.title}>{configuration?.name}</h3>
        </header>

        <ul className={s.assetsList}>
          {assetTypes(flattenChildren(communityArea?.children)).map((asset, index) => (
            <li key={index}>
              <div className={s.assetSquareBtn}>
                <BaseIcon icon={asset?.type ? get2dAssetIcon(asset?.type) : 'house'} size={16.25} />
                <label className={s.assetsAmount}>{formatter.prefixWithZeros(asset.count)}</label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </InViewPositioner>
  );
};
