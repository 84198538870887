import 'react-tooltip/dist/react-tooltip.css';

import React, { useContext, useMemo, useState } from 'react';

import classnames from 'classnames';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { FixedSizeList } from 'react-window';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { ContextMenu } from 'src/components/ContextMenu';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager/ModalAssetsManager.types';
import { EModalSize } from 'src/constants/modals';
import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { ConfigType } from 'src/graphql';
import { useDuplicateCommunity } from 'src/hooks/useDuplicateCommunity';
import { useModal } from 'src/hooks/useModal';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { setModalCommunitySummaryView } from 'src/redux/application/application.slice';
import { selectIsLoggedIn, selectUserRole, selectUsername } from 'src/redux/auth/auth.selectors';
import { setActiveConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { EUserRoles } from 'src/typings/base-types';
import { EAppMode } from 'src/utils/appMode';
import { dispatchErrorToast } from 'src/utils/toasts';

import s from './ListCommunities.module.scss';
import { TListCommunitiesProps } from './ListCommunities.types';
import { ListItem } from './ListItem';

export const ListCommunities: React.FC<TListCommunitiesProps> = ({
  height,
  items,
  removeCommunity,
  updatePersonalCommunitiesList,
  onDuplicationComplete,
  setLoadingStatus,
  isOperationalTab = false,
}) => {
  const { id: modalId } = useModal();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { triggerCommunityDeleteAlert } = useContext(ApplicationContext);

  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const userRole = useSelector(selectUserRole);
  const appMode = useSelector(selectAppMode);
  const userName = useSelector(selectUsername);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });
  const [openedProjectUuid, setOpenedProjectUuid] = useState<string | null>(null);
  const [openedConfigurationUuid, setOpenedConfigurationUuid] = useState<string | undefined>(
    undefined,
  );
  const [openedModalConfigUuid, setOpenedModalConfigUuid] = useState<string>();

  const {
    getDuplicationName,
    duplicateCanaryNetwork,
    duplicateCommunity,
    duplicateCollaboration,
    duplicateCollaborationLoading,
    duplicateConfigLoading,
    duplicateCNLoading,
  } = useDuplicateCommunity();

  const closeActiveModal = () => {
    setOpenedProjectUuid(null);
    setMenuModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  };
  const isAdmin = userRole === EUserRoles.Admin;

  const showInfoToolTip = useMemo(() => !isAdmin, [isAdmin]);

  const onMenuClick = (menuTriggerRef, { communityUuid, configurationUuid, projectID }) => {
    setOpenedModalConfigUuid(communityUuid);
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setOpenedProjectUuid(projectID);
      setOpenedConfigurationUuid(configurationUuid);
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  const openedDropdownParent = items.find((item) => item?.uuid === openedModalConfigUuid);
  const openedDropdownParentIsCN = openedDropdownParent?.configType === ConfigType.CanaryNetwork;

  const openedDropdownParentIsCollaboration =
    openedDropdownParent?.configType === ConfigType.Collaboration;

  const isOwner = openedDropdownParent?.user === userName;

  const dropDownItems: {
    title: string;
    icon: TIconNames;
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  }[] = [];

  if (isLoggedIn && (openedDropdownParentIsCN ? isOwner || isAdmin : true) && !isOperationalTab) {
    dropDownItems.push({
      title: t('commands.DUPLICATE'),
      icon:
        duplicateCollaborationLoading || duplicateConfigLoading || duplicateCNLoading
          ? 'spinner'
          : 'duplicate',
      onClick: async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (openedProjectUuid) {
          const configuration = items.find(
            (community) => community.uuid === openedConfigurationUuid,
          );
          const duplicationName = getDuplicationName(configuration, items);

          try {
            if (openedDropdownParentIsCN) {
              duplicateCanaryNetwork(duplicationName, configuration.uuid).then(() => {
                onDuplicationComplete();
              });
            } else if (openedDropdownParentIsCollaboration) {
              duplicateCollaboration(duplicationName, configuration.uuid).then(() => {
                onDuplicationComplete();
              });
            } else {
              duplicateCommunity(duplicationName, configuration.uuid).then(() => {
                onDuplicationComplete();
              });
            }
          } catch (err) {
            dispatchErrorToast(err);
          }
          closeActiveModal();
          if (setLoadingStatus) setLoadingStatus(true);
        }
      },
    });
  }
  if (openedDropdownParent?.user === userName || isAdmin) {
    dropDownItems.push(
      {
        title: t('commands.EDIT'),
        icon: 'pencil-create' as const,
        onClick: () => {
          if (openedModalConfigUuid) {
            dispatch(setActiveConfigurationUuid(openedModalConfigUuid));
            dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Form));
          }
        },
      },
      {
        title: t('commands.REMOVE'),
        icon: 'custom-trash' as const,
        onClick: async () => {
          if (openedProjectUuid) {
            const name = items.find((community) => community.uuid === openedConfigurationUuid).name;
            await triggerCommunityDeleteAlert(name);
            if (isOperationalTab && openedConfigurationUuid)
              await removeCommunity({ projectUuid: openedConfigurationUuid });
            else await removeCommunity({ projectUuid: openedProjectUuid });
            updatePersonalCommunitiesList();
            closeActiveModal();
          }
        },
      },
    );
  }

  return (
    <div className={s.communitiesList}>
      <div
        id="connect-community-anchor-element"
        data-tooltip-html={ReactDOMServer.renderToString(
          <div className={s.tooltipContent}>
            <h3> 👈 Connect Community</h3>
            <p>
              To connect live asset data you will first be asked to review final member and market
              settings
            </p>
          </div>,
        )}>
        <FixedSizeList
          height={height}
          itemData={{
            onMenuClick,
            items,
          }}
          itemCount={items.length}
          itemSize={appMode === EAppMode.Desktop ? 90 : 215}
          onScroll={closeActiveModal}
          width={EModalSize.L370}
          className={classnames(s.communitiesListWindow)}>
          {ListItem}
        </FixedSizeList>
      </div>
      <Tooltip
        anchorId="connect-community-anchor-element"
        isOpen={isOperationalTab && showInfoToolTip}
        place="right"
        position={{ x: 360, y: 375 }}
      />
      <ContextMenu
        relativeElement={menuModalTriggerRef}
        modalId={modalId}
        position={{
          side: 'right',
          anchorPosition: 50,
        }}
        items={dropDownItems}
      />
    </div>
  );
};
